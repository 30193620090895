import { useEffect } from "react";
import { TextBoxComponent } from '../../shared/components/form_controls/text_box/text_box.component';
import { DropdownComponent } from '../../shared/components/form_controls/dropdown/dropdown.component';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { FormulaTemplateEnumValues } from '../../models/plausibility_check/enums/formula_template.enum';
import Grid from '@mui/material/Grid';
import { createPlausibilityCheckType, getCheckTypeMessages } from './redux/type_create.thunks';
import { updateName, updateFormula, resetState, toggleIsUniqueRules, toggleIsBestMatch, cancelCheck, updateWarningMessageKey, updateErrorMessageKey, createPlausibilityCheckTypeFailed } from "./redux/type_create.slice";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { doesExist } from '../../services/validation.service';
import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import * as style from "./type_create.style";
import { LinkComponent } from '../../shared/components/typography/link.component';
import { LoadingOverlayContainer, LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { getConfig } from "services/config.service";
import { POEditorLinkComponent } from "../../shared/components/editors/poeditor_link/poeditor_link.component";

export const TypeCreateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.plausibilitychecktype_create);

    const warningMessage = state.actualData.selectedWarningMessage?.message?.value;
    const errorMessage = state.actualData.selectedErrorMessage?.message?.value

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(getCheckTypeMessages());
        return () => {
            dispatch(resetState());
          }
    }, [dispatch])

    if (state.command.createPlausibilityCheckType.status === "success") {
        enqueueSnackbar("Prüfungstyp erstellt", { variant: "success" });
        dispatch(resetState());
        navigate("/plausibilitycheck/type");
    }
    if (state.command.createPlausibilityCheckType.status === "error") {
        enqueueSnackbar("Fehler beim Erstellen des Prüfungstyps", { variant: "error" });
        dispatch(createPlausibilityCheckTypeFailed());
    }

    if (state.command.cancelPlausibilityCheckType.status === "success") {
        dispatch(resetState());
        navigate("/plausibilitycheck/type");
    }

    const isSaving = state.command.createPlausibilityCheckType.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={3}>

                    <Grid item md={12}>
                        <TextBoxComponent
                            name="Name Prüfungstyp"
                            value={state.actualData.checkTypeToCreate.name}
                            onChange={(value) => dispatch(updateName(value))}
                            isRequired={true}
                            maxLength={512}
                            isAutoFocus={true}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <DropdownComponent
                            name="Regeltemplate"
                            data={FormulaTemplateEnumValues}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(selectedFormulaTemplate) => dispatch(updateFormula(selectedFormulaTemplate.value))}
                            selectedValue={FormulaTemplateEnumValues.find(x => x.value === state.actualData.checkTypeToCreate.formulaTemplate)}
                            displayedProperties={["text"]}
                        />
                    </Grid>

                    <Grid item md={12}>
                        <EditorSectionHeaderComponent>Warn- & Fehlermeldungen</EditorSectionHeaderComponent>
                    </Grid>

                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}>
                        <Grid item
                            container
                            md={6}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <Grid item>
                                <DropdownComponent
                                    name="Warnmeldung"
                                    data={state.actualData.warningMessages}
                                    isLoading={state.query.getCheckTypeMessages.status === "pending"}
                                    isRequired={false}
                                    onSelect={(selectedMessageKey) => dispatch(updateWarningMessageKey(selectedMessageKey))}
                                    selectedValue={state.actualData.selectedWarningMessage}
                                    displayedProperties={["displayValue"]}
                                />
                            </Grid>
                            {doesExist(warningMessage)
                                ? <Grid item>
                                    <Typography>
                                        {warningMessage}
                                    </Typography>
                                </Grid>
                                : <></>
                            }
                        </Grid>

                        <Grid item
                            container
                            md={6}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <Grid item>
                                <DropdownComponent
                                    name="Fehlermeldung"
                                    data={state.actualData.errorMessages}
                                    isLoading={state.query.getCheckTypeMessages.status === "pending"}
                                    isRequired={false}
                                    onSelect={(selectedMessageKey) => dispatch(updateErrorMessageKey(selectedMessageKey))}
                                    selectedValue={state.actualData.selectedErrorMessage}
                                    displayedProperties={["displayValue"]}
                                />
                            </Grid>
                            {doesExist(errorMessage)
                                ? <Grid item>
                                    <Typography>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                                : <></>
                            }
                        </Grid>
                        <POEditorLinkComponent projectName={getConfig().translation.project.measurements} />
                    </Grid>

                    <Grid item md={6}>
                        <EditorSectionHeaderComponent>Weitere Einstellungen</EditorSectionHeaderComponent>
                    </Grid>

                    <Grid item md={6}>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="isuniquerules-checktype-checkbox"
                                    checked={state.actualData.checkTypeToCreate.isUniqueRules}
                                    onChange={() => dispatch(toggleIsUniqueRules(!state.actualData.checkTypeToCreate.isUniqueRules))}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="eindeutiges Regelwerk"
                        />
                        <div>
                            verhindert, dass sich verschiedene Prüfungsregeln gegenseitig aufheben.
                    </div>
                    </Grid>
                    <Grid item md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="isbestmatch-checktype-checkbox"
                                    checked={state.actualData.checkTypeToCreate.isBestMatchEnabled}
                                    onChange={() => dispatch(toggleIsBestMatch(!state.actualData.checkTypeToCreate.isBestMatchEnabled))}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label="Best-Match (empfohlen)"
                        />
                        <div>
                            Best Match sucht und verwendet nur die Prüfungen, die am besten greifen. Mehr zur Funktionalität im <LinkComponent href="https://wiki.medi.de/x/XgDeAw" target="_blank" rel="noreferrer" >Handbuch</LinkComponent>.
                        </div>
                    </Grid>
                    <EditorButtonComponent
                        canExecute={state.command.createPlausibilityCheckType.canExecute}
                        save={() => dispatch(createPlausibilityCheckType())}
                        cancelSave={() => dispatch(cancelCheck())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};
