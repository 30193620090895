import { Grid, IconButton, Typography } from "@mui/material";
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { TextBoxComponent } from "shared/shared";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICharacteristicValueViewModel, IIndexedValueChanged } from "./characteristic_type_editor.model";
import { doesExist } from "services/validation.service";
import { ITranslationValue } from "models/translation/translation_value";
import { getConfig } from "services/config.service";
import { LinkComponent } from "shared/components/typography/link.component";
import { POEditorLinkComponent } from "../../../shared/components/editors/poeditor_link/poeditor_link.component";

interface CharacteristicTypeEditorProps {
    header: string;
    name: string;
    shortKey: string;
    values: ICharacteristicValueViewModel[];
    translations: ITranslationValue[];
    changeName: (name: string) => void;
    changeShortKey: (shortKey: string) => void;
    changeValueName: (value: IIndexedValueChanged) => void;
    changeValueKey: (value: IIndexedValueChanged) => void;
    addValue: () => void;
    deleteValue: (index: number) => void;
}

export const CharacteristicTypeEditorComponent: React.FC<CharacteristicTypeEditorProps> = (
    {
        header,
        name,
        shortKey,
        values,
        translations,
        changeName,
        changeShortKey,
        changeValueName,
        changeValueKey,
        addValue,
        deleteValue
    }) => {

    const getTranslation = (key: string) : string => {
        const translation = translations.filter(x => x.name === key);

        return translation.length >= 1 ? translation[0].translation : undefined;
    } 

    const disabledAddButton = values.filter(x => x.name === '' || x.translationKey === '').length > 0;

    return (
        <Grid container spacing={3} direction={'column'}>
            <Grid item >
                <EditorSectionHeaderComponent>{header}</EditorSectionHeaderComponent>
            </Grid>
            <Grid item>
                <TextBoxComponent
                    name="Name"
                    value={name}
                    onChange={changeName}
                    isRequired={true}
                    maxLength={512}
                    isAutoFocus={true}
                />
                <TextBoxComponent
                    name="Kürzel"
                    value={shortKey}
                    onChange={changeShortKey}
                    isRequired={true}
                    minLength={3}
                    maxLength={3}
                    isAutoFocus={false}
                />
            </Grid>
            <Grid item>
                <EditorSectionHeaderComponent>Ausprägungswerte</EditorSectionHeaderComponent>  
            </Grid>
            <Grid item direction={'column'}>
                {
                    values.map((value, index) => {
                        return (
                            <>
                                <Grid item key={`value_${index}`} 
                                    sx={{marginBottom: '20px'}} direction={'row'}>
                                    <TextBoxComponent
                                        name="Wert"
                                        value={value.name}
                                        onChange={(value) => changeValueName({index, value})}
                                        isRequired={true}
                                        maxLength={512}
                                        isAutoFocus={true}
                                    /> 
                                    <TextBoxComponent
                                        name="Übersetzungskey"
                                        value={value.translationKey}
                                        onChange={(value) => changeValueKey({index, value})}
                                        isRequired={true}
                                        maxLength={100}
                                        isAutoFocus={false}
                                    />                         
                                    <IconButton
                                        style={{ outline: "none"}}
                                        onClick={() => deleteValue(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                {doesExist(getTranslation(value.translationKey)) ?
                                    <Grid item key={`translation_${index}`}  sx={{marginBottom: '20px'}}>
                                        <Typography>
                                            {getTranslation(value.translationKey)}
                                        </Typography> 
                                    </Grid>
                                    : <></>
                                    
                                }
                            </>
                        );
                    })
                }
            </Grid>
            {values.length > 0 && <POEditorLinkComponent projectName={getConfig().translation.project.additions}/>}
            <Grid item md={6} direction={'row'}>
                <ListButtonComponent style={{width: '50%'}}
                    onClick={addValue}
                    disabled={disabledAddButton}>
                    <AddIcon /> Wert hinzufügen
                </ListButtonComponent>
            </Grid>
        </Grid>
    );
};