import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAvailableFootType from 'models/available_basedata/available_foot_type';
import IAvailableArticleType from '../../../models/available_basedata/available_article_type';
import { MassMaskCommonInfoCreateState, SelectBodyInformationPayload } from './massmask_common_info_create.model';
import { createMassMask, getProductLines, selectBodyArea, selectProductLine, selectBodyAreaArticleType, selectBodyAreaFootType, getBodyInformations } from './massmask_common_info_create.thunks';
import { updateCanExecuteCreate } from './massmask_common_info.create.reducer';
import { SideOrderEnum } from 'models/massmasks/side_order_enum';

const initialState: MassMaskCommonInfoCreateState = {
    actualData: {
        productLines: [],
        bodyAreas: [],
        articleTypes: [],
        footTypes: [],
        selectedProductLine: null,
        selectedBodyArea: null,
        selectedArticleType: null,
        selectedFootType: null,
        createdMassMaskId: null,
        isArticleTypeReadony: true,
        isFootTypeReadony: true,
        isBodyAreaReadonly: true,
        isPantyTopWithStandardDimensions: false,
        isEllipticalShapeKneeFunctionZone: false,
        hideFingersAndToes: false,
        sideOrder: SideOrderEnum.LeftRight,
        bodyInformationSelection: [],
    },
    loadedData: {
        bodyInformations: []
    },
    query: {
        getProductLines: { status: "idle", canExecute: true },
        getBodyAreas: { status: "idle", canExecute: true },
        getBodyAreaData: { status: "idle", canExecute: true },
        getArticleTypes: { status: "idle", canExecute: true },
        getFootTypes: { status: "idle", canExecute: true },
        getBodyInformations: { status: "idle", canExecute: true },
    },
    command: {
        createMassMask: { status: "idle", canExecute: false }
    }
}

export const massMaskCommonInfoCreateSlice = createSlice({
    name: 'massMask/commonInfo/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        selectArticleType: (state, action: PayloadAction<IAvailableArticleType>) => {
            state.actualData.selectedArticleType = action.payload;
            state.actualData.selectedFootType = null;
            state.command.createMassMask.canExecute = updateCanExecuteCreate(state);
        },
        selectFootType: (state, action: PayloadAction<IAvailableFootType>) => {
            state.actualData.selectedFootType = action.payload;
            state.actualData.selectedArticleType = null;
            state.command.createMassMask.canExecute = updateCanExecuteCreate(state);
        },
        setMassMaskIsPantyTopWithStandardDimensions: (state, action: PayloadAction<boolean>) => {
            state.actualData.isPantyTopWithStandardDimensions = action.payload;
        },
        setMassMaskIsEllipticalShapeKneeFunctionZone: (state, action: PayloadAction<boolean>) => {
            state.actualData.isEllipticalShapeKneeFunctionZone = action.payload;
        },
        setHideFingersAndToes: (state, action: PayloadAction<boolean>) => {
            state.actualData.hideFingersAndToes = action.payload;
        },
        selectBodyInformation: (state, action: PayloadAction<SelectBodyInformationPayload>) => {
            const selectBodyInformationPayload = action.payload;
            state.actualData.bodyInformationSelection[selectBodyInformationPayload.arrayPosition].id = selectBodyInformationPayload.id;
            state.command.createMassMask.canExecute = updateCanExecuteCreate(state);
        },
        moveUp: (state, action: PayloadAction<number>) => {
            const indexToMove = action.payload;
            if (indexToMove > 0) {
                var itemToMove = state.actualData.bodyInformationSelection[indexToMove];
                var previousItem = state.actualData.bodyInformationSelection[indexToMove - 1];

                state.actualData.bodyInformationSelection[indexToMove] = previousItem;
                state.actualData.bodyInformationSelection[indexToMove - 1] = itemToMove;
            }
        },
        moveDown: (state, action: PayloadAction<number>) => {
            const indexToMove = action.payload;
            if (indexToMove < state.actualData.bodyInformationSelection.length - 1) {
                var itemToMove = state.actualData.bodyInformationSelection[indexToMove];
                var nextItem = state.actualData.bodyInformationSelection[indexToMove + 1];

                state.actualData.bodyInformationSelection[indexToMove] = nextItem;
                state.actualData.bodyInformationSelection[indexToMove + 1] = itemToMove;
            }
        },
        removeBodyInformation: (state, action: PayloadAction<number>) => {
            state.actualData.bodyInformationSelection.splice(action.payload, 1);
            state.command.createMassMask.canExecute = updateCanExecuteCreate(state);
        },
        addBodyInformation: (state) => {
            state.actualData.bodyInformationSelection.push({
                id: state.loadedData.bodyInformations[0].id
            });
            state.command.createMassMask.canExecute = updateCanExecuteCreate(state);
        },
        updateSideOrder: (state,  action: PayloadAction<SideOrderEnum>) => {
            state.actualData.sideOrder = action.payload;
        },
    }, extraReducers: (builder) => {
        // getProductLines 
        builder.addCase(getProductLines.pending, (state, action) => {
            state.query.getProductLines.status = 'pending'
        }).addCase(getProductLines.rejected, (state, action) => {
            state.query.getProductLines.status = "error"
            state.query.getProductLines.message = action.error.message;
        }).addCase(getProductLines.fulfilled, (state, action) => {
            state.query.getProductLines.status = "success"
            state.query.getProductLines.canExecute = true;

            state.actualData.productLines = action.payload.getData();
            state.actualData.bodyAreas = []
            state.actualData.articleTypes = []
            state.actualData.selectedProductLine = null;
            state.actualData.selectedBodyArea = null;
            state.actualData.selectedArticleType = null;
            state.command.createMassMask.canExecute = false;

            // selectProductLine
        }).addCase(selectProductLine.pending, (state, action) => {
            state.query.getBodyAreas.status = 'pending'
            state.actualData.selectedProductLine = action.meta.arg;
        }).addCase(selectProductLine.rejected, (state, action) => {
            state.query.getBodyAreas.status = "error"
            state.query.getBodyAreas.message = action.error.message;
        }).addCase(selectProductLine.fulfilled, (state, action) => {
            state.query.getBodyAreas.status = "success"
            state.query.getBodyAreas.canExecute = true;
            state.actualData.bodyAreas = action.payload.getData();
            state.actualData.articleTypes = []
            state.actualData.selectedBodyArea = null;
            state.actualData.selectedArticleType = null;
            state.actualData.selectedFootType = null;
            state.actualData.isBodyAreaReadonly = false;
            state.actualData.isArticleTypeReadony = true;
            state.actualData.isFootTypeReadony = true;
            state.command.createMassMask.canExecute = false;

            // selectBodyArea
        }).addCase(selectBodyArea.pending, (state, action) => {
            state.query.getBodyAreaData.status = 'pending'
            state.actualData.selectedBodyArea = action.meta.arg;
        }).addCase(selectBodyArea.rejected, (state, action) => {
            state.query.getBodyAreaData.status = "error"
            state.query.getBodyAreaData.message = action.error.message;
        }).addCase(selectBodyArea.fulfilled, (state, action) => {
            state.query.getBodyAreaData.status = "success"
            state.query.getBodyAreaData.canExecute = true;
            state.actualData.isBodyAreaReadonly = false;
            state.command.createMassMask.canExecute = false;

            // selectBodyAreaArticleType
        }).addCase(selectBodyAreaArticleType.pending, (state, action) => {
            state.query.getArticleTypes.status = 'pending'
        }).addCase(selectBodyAreaArticleType.rejected, (state, action) => {
            state.query.getArticleTypes.status = "error"
            state.query.getArticleTypes.message = action.error.message;
        }).addCase(selectBodyAreaArticleType.fulfilled, (state, action) => {
            state.query.getArticleTypes.status = "success"
            state.query.getArticleTypes.canExecute = true;
            state.actualData.articleTypes = action.payload.getData();
            state.actualData.selectedArticleType = null;
            state.actualData.isBodyAreaReadonly = false;
            state.actualData.isArticleTypeReadony = false;

            // selectBodyAreaFootType
        }).addCase(selectBodyAreaFootType.pending, (state, action) => {
            state.query.getFootTypes.status = 'pending'
        }).addCase(selectBodyAreaFootType.rejected, (state, action) => {
            state.query.getFootTypes.status = "error"
            state.query.getFootTypes.message = action.error.message;
        }).addCase(selectBodyAreaFootType.fulfilled, (state, action) => {
            state.query.getFootTypes.status = "success"
            state.query.getFootTypes.canExecute = true;
            state.actualData.footTypes = action.payload.getData();
            state.actualData.selectedFootType = null;
            state.actualData.isFootTypeReadony = false;

            // createMassMask 
        }).addCase(createMassMask.pending, (state, action) => {
            state.command.createMassMask.status = 'pending'
            state.command.createMassMask.canExecute = false;
        }).addCase(createMassMask.rejected, (state, action) => {
            state.command.createMassMask.status = "error"
            state.command.createMassMask.canExecute = true;
            state.command.createMassMask.message = action.error.message;
        }).addCase(createMassMask.fulfilled, (state, action) => {
            state.command.createMassMask.status = "success"
            state.command.createMassMask.canExecute = true;
            state.actualData.createdMassMaskId = action.payload.getData();

            //getBodyInformations
        }).addCase(getBodyInformations.pending, (state, action) => {
            state.query.getBodyInformations.status = 'pending'
        }).addCase(getBodyInformations.rejected, (state, action) => {
            state.query.getBodyInformations.status = "error"
            state.query.getBodyInformations.message = action.error.message;
        }).addCase(getBodyInformations.fulfilled, (state, action) => {
            state.query.getBodyInformations.status = "success"
            state.query.getBodyInformations.canExecute = true;

            const data = action.payload.getData();
            state.loadedData.bodyInformations = data;
        })
    }
})

export const {
    resetState,
    selectArticleType,
    selectFootType,
    setMassMaskIsPantyTopWithStandardDimensions,
    setMassMaskIsEllipticalShapeKneeFunctionZone,
    setHideFingersAndToes,
    selectBodyInformation,
    moveUp,
    moveDown,
    removeBodyInformation,
    addBodyInformation,
    updateSideOrder
} = massMaskCommonInfoCreateSlice.actions

export default massMaskCommonInfoCreateSlice.reducer