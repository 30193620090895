import { Grid } from '@mui/material';
import React from 'react';
import { DropdownComponent, RadioBoxAdvancedComponent, TextBoxComponent } from 'shared/shared';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import { SideEnum } from 'models/additions/addition_side.enum';
import { EnumDescriptor } from 'models/enum_descriptor';
import { AdditionBaseTranslationComponent } from './addition_base_translation.component';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { getConfig } from "services/config.service";
import { IAdditionEditor, IAdditionInitial } from 'models/additions/addition_editor.model';
import { QuantityCalculationModeEnum, QuantityCalculationModeValues } from 'models/additions/quantity_calculation_mode.enum';
import { AdditionPlaceHolderTypeEnum } from 'models/additions/addition_placeholder.enum';
import { POEditorLinkComponent } from '../../../../shared/components/editors/poeditor_link/poeditor_link.component';

interface AdditionBaseComponentProps {
    additionEditor: IAdditionEditor,
    additionInitial: IAdditionInitial,
    updateCategory: (category: IAdditionCategoryOverview) => {},
    updateQuantityCalculationMode: (quantityCalculationMode: EnumDescriptor<QuantityCalculationModeEnum>) => {},
    updateSide: (side: EnumDescriptor<SideEnum>) => {},
    updateIsGusset: (side: EnumDescriptor<boolean>) => {},
    updateIsMultiply: (isMultiply: EnumDescriptor<boolean>) => {},
}

export const AdditionBaseComponent: React.FC<AdditionBaseComponentProps> = ({ additionEditor, additionInitial, updateQuantityCalculationMode, updateSide, updateCategory, updateIsGusset, updateIsMultiply }) => {
    const quantity = additionEditor.addition.quantityCode + " - " + additionEditor.addition.quantityText;

    const calculateQuantityCalculationMode = () => {
        let allowedQuantityCalculationModes: EnumDescriptor<QuantityCalculationModeEnum>[] = [];
        allowedQuantityCalculationModes.push(QuantityCalculationModeValues.filter(qc => qc.value === QuantityCalculationModeEnum.QuantityUnit)[0]);
        allowedQuantityCalculationModes.push(QuantityCalculationModeValues.filter(qc => qc.value === QuantityCalculationModeEnum.OrderQuantity)[0]);

        if(additionEditor.addition.placeholder.placeholderTemplate.value === AdditionPlaceHolderTypeEnum.RangeInput) {
            allowedQuantityCalculationModes.push(QuantityCalculationModeValues.filter(qc => qc.value === QuantityCalculationModeEnum.RangeValue)[0]);
        }

        if(additionEditor.selectedPositioningOption.value) {
            allowedQuantityCalculationModes.push(QuantityCalculationModeValues.filter(qc => qc.value === QuantityCalculationModeEnum.PositionsCount)[0]);
        }

        return allowedQuantityCalculationModes;
    }

    return (<div>
        <EditorSectionHeaderComponent>Basisdaten</EditorSectionHeaderComponent>
        <div style={{ marginTop: 2 }}>
            <Grid container
                spacing={3}
                direction="column"
                justifyContent="space-between">
                <Grid item
                    container
                    direction="row">
                    <Grid item
                        md={3}>
                        <TextBoxComponent
                            name="ERP-Artikelnummer"
                            value={additionEditor.addition.option}
                            onChange={() => { }}
                            isRequired={false}
                            isReadonly={true}
                        />
                    </Grid>
                    <Grid item
                        md={3}>
                        <TextBoxComponent
                            name="Zusatzcode"
                            value={additionEditor.addition.code}
                            onChange={() => { }}
                            isRequired={false}
                            isReadonly={true}
                        />
                    </Grid>
                    <Grid item
                        md={3}>
                        <DropdownComponent name="Zusatzkategorien"
                            data={additionInitial.categories}
                            isRequired={true}
                            onSelect={updateCategory}
                            displayedProperties={["categoryText"]}
                            selectedValue={additionEditor.addition.category}
                        />
                    </Grid>
                    <Grid item
                        md={6}>
                        <TextBoxComponent
                            name="Zusatzbeschreibung"
                            value={additionEditor.addition.descriptionLong}
                            onChange={() => { }}
                            isRequired={false}
                            isReadonly={true}
                            isMultiLine={true}
                            width="100%"
                        />
                    </Grid>
                </Grid>
                <Grid item
                    container
                    spacing={3}
                    direction="row">
                    <Grid item
                        md={3}>
                        <TextBoxComponent
                            name="Menge/Stück"
                            value={quantity}
                            onChange={() => { }}
                            isRequired={false}
                            isReadonly={true}
                        />
                    </Grid>
                    <Grid item
                        md={3}>
                        <DropdownComponent
                            name="Mengenberechnung"
                            data={calculateQuantityCalculationMode()}
                            onSelect={updateQuantityCalculationMode}
                            isRequired={true}
                            displayedProperties={["text"]}
                            selectedValue={additionEditor.addition.quantityCalculationMode}
                        />
                    </Grid>
                    <Grid item
                        md={3}>
                        <DropdownComponent name="Seite"
                            data={additionInitial.sides}
                            isRequired={true}
                            onSelect={updateSide}
                            displayedProperties={["text"]}
                            selectedValue={additionEditor.addition.side}

                        />
                    </Grid>
                </Grid>
                <Grid item
                    spacing={3}
                    container>
                    <Grid item
                        md={3}>
                        <TextBoxComponent
                            name="Bezeichnung"
                            value={additionEditor.addition.description}
                            onChange={() => { }}
                            isRequired={false}
                            isReadonly={true}
                            width="100%"
                        />
                    </Grid>
                    <Grid item
                        container
                        md={6}
                        direction="row"
                        alignItems='flex-end'>
                        <Grid item>
                            <AdditionBaseTranslationComponent
                                hasTranslation={additionEditor.addition.hasDescription}
                            />
                        </Grid>
                        <POEditorLinkComponent projectName={getConfig().translation.project.additions} />
                    </Grid>
                </Grid>
                <Grid item
                    container>
                    <Grid item
                        md={3}>
                        <RadioBoxAdvancedComponent name="Der Zusatz ist ein Zwickel"
                            data={additionInitial.isGussetOptions}
                            displayedProperties={["text"]}
                            onSelect={updateIsGusset}
                            isRequired={false}
                            selectedItem={additionEditor.addition.isGusset}
                        />
                    </Grid>
                    <Grid item
                        md={3}>
                        <RadioBoxAdvancedComponent name="Der Zusatz ist multiplizierbar"
                            data={additionInitial.isMultiplyOptions}
                            displayedProperties={["text"]}
                            onSelect={updateIsMultiply}
                            isRequired={false}
                            selectedItem={additionEditor.addition.isMultiply}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>);
}