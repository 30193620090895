import { closeProductConfigurationRunPolicies } from '../redux/product_configurations_overview.slice';
import { JsonViewer } from '@textea/json-viewer';;
import ClearIcon from "@mui/icons-material/Clear";
import { AppDispatch } from '../../../../app/redux_store';
import { useAppDispatch } from '../../../../app/hook';
import { PrimaryButtonComponent } from '../../../../shared/components/buttons/primary_button.component';
import { Grid } from '@mui/material';

interface ProductConfigurationRunDetailsComponentProps {
    productConfigurationRunPolicies?: string;
};

export const ProductConfigurationRunPoliciesComponent = (props: ProductConfigurationRunDetailsComponentProps) => {
    const dispatch: AppDispatch = useAppDispatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}>

            <Grid
                item
                container
                justifyContent="flex-end">

                <PrimaryButtonComponent onClick={() => dispatch(closeProductConfigurationRunPolicies())}>
                    <ClearIcon /> Zurück zur Übersicht
                </PrimaryButtonComponent>
            </Grid>

            <Grid item>
                <JsonViewer displayDataTypes={false} value={JSON.parse(props.productConfigurationRunPolicies)} />
            </Grid>
    </Grid>
    );
}