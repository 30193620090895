import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { InitOptions } from "i18next";
import { languages, namespaces } from "shared/translation/translation_constants";
import { getConfig } from "./config.service";

const translation = async (init: InitOptions) => {
    if (!i18next.isInitialized) {
        await i18next
            .use(initReactI18next)
            .use(ChainedBackend)
            .use(LanguageDetector)
            .init(init);

        console.log("i18 next initialized")
    }
}

export const initTranslation = () => {
    translation({
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    defaultVersion: "1.0",
                    expirationTime: getConfig().name === "dev" ? 10 * 1000 : 7 * 24 * 60 * 60 * 1000,
                },
                {
                    loadPath: "/api/localization/{{ns}}?language={{lng}}"
                }
            ]
        },
        react: {
            useSuspense: true
        },
        detection: {
            caches: [],
            excludeCacheFor: []
        },
        ns: [
            namespaces.MTM
        ],
        defaultNS: namespaces.MTM,
        supportedLngs: [languages.de, languages.en],
        fallbackLng: languages.de,
        nonExplicitSupportedLngs: true
    })
};

export default i18next;