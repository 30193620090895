import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductConfigurationsOverviewState, SelectedProductConfigurationRun, ViewStateEnum } from './product_configurations_overview.model';
import { filterProductConfigurations, sortByCreatedAtDescending } from './product_configurations_overview.reducer';
import { cloneProductConfiguration, getProductConfigurationRunDetails, getProductConfigurationRunPolicies, getProductConfigurations, loadProductConfiguration, loadProductConfigurationRun, lockProductConfiguration, unlockProductConfiguration } from './product_configurations_overview.thunks';

const initialState: ProductConfigurationsOverviewState = {
    loadedData: {
        productConfigurations: [],
    },
    actualData: {
        viewState: ViewStateEnum.List,
        productConfigurations: [],
        productConfigurationPages: [],
        selectedProductConfigurationRun: null,
        searchFilter: "",
        currentPageIndex: 0,
        pageSize: 10,
    },
    query: {
        getProductConfigurations: { status: "idle", canExecute: true },
        getProductConfigurationRunDetails: { status: "idle", canExecute: true },
        getProductConfigurationRunPolicies: { status: "idle", canExecute: true },
    },
    command: {
        loadProductConfiguration: { status: "idle", canExecute: false },
        loadProductConfigurationRun: { status: "idle", canExecute: false },
        lockProductConfiguration: { status: "idle", canExecute: false },
        unlockProductConfiguration: { status: "idle", canExecute: false },
        cloneProductConfiguration: { status: "idle", canExecute: false }
    }
}

export const productConfigurationsOverviewSlice = createSlice({
    name: 'simulator/productConfigurations/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setProductconfiguration: (state, action: PayloadAction<string>) => {
            const productConfiguration = state.loadedData.productConfigurations.find(x => x.id === action.payload);
            state.actualData.selectedProductConfigurationRun = {
                productConfigurationId: action.payload,
                productConfigurationRunId: null,
                articleTypeErpId: productConfiguration.articleTypeErpId,
                bodyAreaErpId: productConfiguration.bodyAreaErpId,
                mainProductLineErpId: productConfiguration.mainProductLineErpId,
                isCustomDesign: productConfiguration.isCustomDesign
            };
        },
        setProductconfigurationRun: (state, action: PayloadAction<SelectedProductConfigurationRun>) => {
            state.actualData.selectedProductConfigurationRun = action.payload;

            const productConfiguration = state.loadedData.productConfigurations.find(x => x.id === action.payload.productConfigurationId);
            const productConfigurationRun = productConfiguration.productConfigurationRuns.find(x => x.id === action.payload.productConfigurationRunId);
            state.actualData.selectedProductConfigurationRun.articleTypeErpId = productConfigurationRun.articleTypeErpId;
            state.actualData.selectedProductConfigurationRun.bodyAreaErpId = productConfigurationRun.bodyAreaErpId;
            state.actualData.selectedProductConfigurationRun.mainProductLineErpId = productConfigurationRun.mainProductLineErpId;
        },
        resetProductconfiguration: (state) => {
            state.actualData.selectedProductConfigurationRun = null;
        },
        completeLockProductConfiguration: (state) => {
            state.command.lockProductConfiguration.status = 'idle';
        },     
        completeUnlockProductConfiguration: (state) => {
            state.command.unlockProductConfiguration.status = 'idle';
        },
        closeProductConfigurationRunDetails: (state) => {
            state.actualData.viewState = ViewStateEnum.List;
        },
        closeProductConfigurationRunPolicies: (state) => {
            state.actualData.viewState = ViewStateEnum.List;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterProductConfigurations(state);
        },    
        nextPage: (state) => {
            state.actualData.currentPageIndex = state.actualData.currentPageIndex + 1;
        },    
        previousPage: (state) => {
            state.actualData.currentPageIndex = state.actualData.currentPageIndex - 1;

        },    
        changePageSize: (state, action: PayloadAction<number>) => {
            state.actualData.currentPageIndex = 0;
            state.actualData.pageSize = action.payload;
            filterProductConfigurations(state);
        },
    }, extraReducers: (builder) => {
        // getProductConfigurations
        builder.addCase(getProductConfigurations.pending, (state, action) => {
            state.query.getProductConfigurations.status = "pending"
            state.query.getProductConfigurations.canExecute = false;
        }).addCase(getProductConfigurations.rejected, (state, action) => {
            state.query.getProductConfigurations.status = "error"
            state.query.getProductConfigurations.message = action.error.message;
        }).addCase(getProductConfigurations.fulfilled, (state, action) => {
            state.query.getProductConfigurations.status = "success"
            const productConfigurations = action.payload.getData();
            state.loadedData.productConfigurations = productConfigurations;
            state.actualData.productConfigurations = sortByCreatedAtDescending(state.loadedData.productConfigurations);
            filterProductConfigurations(state);
        })

        // loadProductConfiguration
        .addCase(loadProductConfiguration.pending, (state, action) => {
            state.command.loadProductConfiguration.status = "pending"
        }).addCase(loadProductConfiguration.fulfilled, (state, action) => {
            state.command.loadProductConfiguration.status = "success"
        })

        // loadProductConfigurationRun
        .addCase(loadProductConfigurationRun.pending, (state, action) => {
            state.command.loadProductConfigurationRun.status = "pending"
        }).addCase(loadProductConfigurationRun.fulfilled, (state, action) => {
            state.command.loadProductConfigurationRun.status = "success"
        })    
        
        // cloneProductConfiguration',
        .addCase(cloneProductConfiguration.pending, (state) => {
            state.command.cloneProductConfiguration.status = "pending"
        }).addCase(cloneProductConfiguration.rejected, (state, action) => {
            state.command.cloneProductConfiguration.status = "error"
            state.command.cloneProductConfiguration.message = action.error.message;
        }).addCase(cloneProductConfiguration.fulfilled, (state, action ) => {
            state.command.cloneProductConfiguration.status = "success"
            state.command.cloneProductConfiguration.message = `Die Konfiguration wurde mit der Id ${action.payload.getData().productConfigurationId} dupliziert.`;
        }) 

        // lockProductConfiguration',
        .addCase(lockProductConfiguration.pending, (state, action) => {
            state.command.lockProductConfiguration.status = "pending"
        }).addCase(lockProductConfiguration.rejected, (state, action) => {
            state.command.lockProductConfiguration.status = "error"
            state.command.lockProductConfiguration.message = action.error.message;
        }).addCase(lockProductConfiguration.fulfilled, (state, action) => {
            state.command.lockProductConfiguration.status = "success"
            const updatedLoadedProductConfiguration = state.loadedData.productConfigurations.find(x=> x.id === action.meta.arg);
            updatedLoadedProductConfiguration.isLocked = true;    
            const updatedActualProductConfiguration = state.actualData.productConfigurations.find(x=> x.id === action.meta.arg);
            updatedActualProductConfiguration.isLocked = true;
            filterProductConfigurations(state);
        })   
        
        // unlockProductConfiguration',
        .addCase(unlockProductConfiguration.pending, (state, action) => {
            state.command.unlockProductConfiguration.status = "pending"
        }).addCase(unlockProductConfiguration.rejected, (state, action) => {
            state.command.unlockProductConfiguration.status = "error"
            state.command.unlockProductConfiguration.message = action.error.message;
        }).addCase(unlockProductConfiguration.fulfilled, (state, action) => {
            state.command.unlockProductConfiguration.status = "success"
            const updatedLoadedProductConfiguration = state.loadedData.productConfigurations.find(x=> x.id === action.meta.arg);
            if(updatedLoadedProductConfiguration){
                updatedLoadedProductConfiguration.isLocked = false;    
                const updatedActualProductConfiguration = state.actualData.productConfigurations.find(x=> x.id === action.meta.arg);
                updatedActualProductConfiguration.isLocked = false;
            }
            filterProductConfigurations(state);
        })

        // getProductConfigurationDetails
        .addCase(getProductConfigurationRunDetails.pending, (state, action) => {
            state.query.getProductConfigurationRunDetails.status = "pending"
            state.query.getProductConfigurationRunDetails.canExecute = false;
        }).addCase(getProductConfigurationRunDetails.rejected, (state, action) => {
            state.query.getProductConfigurationRunDetails.status = "error"
            state.query.getProductConfigurationRunDetails.message = action.error.message;
        }).addCase(getProductConfigurationRunDetails.fulfilled, (state, action) => {
            state.query.getProductConfigurationRunDetails.status = "success"
            const data = action.payload.getData();
            state.loadedData.productConfigurationRunDetails = data;
            state.actualData.productConfigurationRunDetails = data;
            state.actualData.viewState = ViewStateEnum.Details;
        })

        // getProductConfigurationRunPolicies
        .addCase(getProductConfigurationRunPolicies.pending, (state, action) => {
            state.query.getProductConfigurationRunPolicies.status = "pending"
            state.query.getProductConfigurationRunPolicies.canExecute = false;
        }).addCase(getProductConfigurationRunPolicies.rejected, (state, action) => {
            state.query.getProductConfigurationRunPolicies.status = "error"
            state.query.getProductConfigurationRunPolicies.message = action.error.message;
        }).addCase(getProductConfigurationRunPolicies.fulfilled, (state, action) => {
            state.query.getProductConfigurationRunPolicies.status = "success"
            const data = action.payload.getData();
            state.loadedData.productConfigurationRunPolicies = data;
            state.actualData.viewState = ViewStateEnum.Policies;
        })
    }
})

export const {
    resetState,
    closeProductConfigurationRunDetails,
    setProductconfiguration,
    setProductconfigurationRun,
    resetProductconfiguration,
    completeLockProductConfiguration,
    completeUnlockProductConfiguration,
    setSearchFilter,
    nextPage,
    previousPage,
    changePageSize,
    closeProductConfigurationRunPolicies,
} = productConfigurationsOverviewSlice.actions

export default productConfigurationsOverviewSlice.reducer