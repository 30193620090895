import { OfferWithoutMasspointsRequest } from "models/simulator/offer_without_masspoints_request";
import { ProductConfiguration } from "../models/simulator/product_configuration";
import { ProductConfigurationRunDetails } from '../models/simulator/product_configuration_run_details';
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { ProductConfigurationResponse } from "models/simulator/product_configuration_evaluation_response";
import { SaveCustomDesignRequest } from "models/simulator/save_custom_design_request";
import { UpdateCustomDesignRequest } from "models/simulator/update_custom_design.request";
import { doesExist } from "./validation.service";
import { ProcessTypeEnum } from "models/simulator/process_type.enum";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { CloneProductConfigurationRunPayload } from "simulator/product_configurations/overview/redux/product_configurations_overview.model";

class SimulatorServiceAPI extends HttpServiceCommonResult {
    public async getProductConfigurations(): Promise<ApiResult<ProductConfiguration[]>> {
        return this.get<Array<ProductConfiguration>>('/api/productconfiguration')
    }

    public async getProductConfigurationRunDetails(productConfigurationId: string, productConfigurationRunId?: number): Promise<ApiResult<ProductConfigurationRunDetails>> {
        if(doesExist(productConfigurationRunId)) {
            return this.get<ProductConfigurationRunDetails>(`/api/productconfiguration/${productConfigurationId}/configurationrun/${productConfigurationRunId}`)
        } else {   
            return this.get<ProductConfigurationRunDetails>(`/api/productconfiguration/${productConfigurationId}`);
        }
    }   
    
    public async getProductConfigurationRunPolicies(productConfigurationId: string, productConfigurationRunId?: number): Promise<ApiResult<any>> {
        if(doesExist(productConfigurationRunId)) {
            return this.get<any>(`/api/productconfiguration/${productConfigurationId}/configurationpolicies/${productConfigurationRunId}`)
        } else {   
            return this.get<any>(`/api/productconfiguration/${productConfigurationId}`);
        }
    }

    public async postOfferWithoutMasspoint(request: OfferWithoutMasspointsRequest): Promise<ApiResult<ProductConfigurationResponse>> {
        return this.post<OfferWithoutMasspointsRequest, ProductConfigurationResponse>(`/api/productconfiguration/offerwithoutmasspoints`, request);
    }

    public async postCustomDesignOffer(request: SaveCustomDesignRequest): Promise<ApiResult<ProductConfigurationResponse>> {
        return this.post<SaveCustomDesignRequest, ProductConfigurationResponse>(`/api/customdesign/offer`, request);
    }

    public async postCustomDesignOrder(request: SaveCustomDesignRequest): Promise<ApiResult<ProductConfigurationResponse>> {
        return this.post<SaveCustomDesignRequest, ProductConfigurationResponse>(`/api/customdesign`, request);
    }

    public async putSimulationTypeToMass(queryParameter: string): Promise<ApiResult<ProductConfigurationResponse>> {
        return this.get<ProductConfigurationResponse>(`/api/productconfiguration/massmask` + queryParameter);
    }

    public async putCustomDesign(request: UpdateCustomDesignRequest, processType: ProcessTypeEnum): Promise<ApiResult<ProductConfigurationResponse>> {
        if(processType === ProcessTypeEnum.Order) {
            return this.put<UpdateCustomDesignRequest, ProductConfigurationResponse>(`/api/customdesign`, request);
        } else {
            return this.put<UpdateCustomDesignRequest, ProductConfigurationResponse>(`/api/customdesign/offer`, request);
        }
    }

    public async cloneProductConfiguration(productConfiguration: CloneProductConfigurationRunPayload, system: string): Promise<ApiResult<ProductConfigurationResponse>> {
        let path = `/api/productconfiguration/${productConfiguration.productConfigurationId}/clone/${system}`;
        if(productConfiguration.productConfigurationRunId){
            path = path + `?configurationRunId=${productConfiguration.productConfigurationRunId}`;
        }
        return this.post<void, ProductConfigurationResponse>(path,undefined);
    }  

    public async lockProductConfiguration(productConfigurationId: string, system: string): Promise<ApiResult<void>> {
        return this.put<string, void>(`/api/productconfiguration/${productConfigurationId}/lockProductConfiguration`, system);
    }  
    
    public async unlockProductConfiguration(productConfigurationId: string, system: string): Promise<ApiResult<void>> {
        return this.put<string, void>(`/api/productconfiguration/${productConfigurationId}/unlockProductConfiguration`, system);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const SimulatorService = new SimulatorServiceAPI(TimeoutMilliseconds);

export default SimulatorService;
