import { BodyInformationType, BodyInformationTypeEnumValues, IBodyInformationEditor, IBodyInformationEditorAction } from "./bodyinformation_editor.model";
import { EditorDescriptionComponent } from "shared/components/editors/editor_description/editor_description.style";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import Grid from "@mui/material/Grid";
import { DropdownComponent, TextBoxComponent } from "shared/shared";
import { FormControlLabel, IconButton, Radio, Typography } from "@mui/material";
import { LinkComponent } from "shared/components/typography/link.component";
import { getConfig } from "services/config.service";
import { DecimalInputComponent } from "shared/components/input/numberInput/decimal_input.component";
import { POEditorLinkComponent } from "../../../shared/components/editors/poeditor_link/poeditor_link.component";

interface BodyInformationEditorComponentProps {
    editor: IBodyInformationEditor,
    editorActions: IBodyInformationEditorAction
}

export const BodyInformationEditorComponent: React.FC<BodyInformationEditorComponentProps> = (
    {
        editor,
        editorActions
    }) => {

    return (
        <>
            <Grid container
                direction="column"
                spacing={3}>
                <Grid item md={6}>
                    <TextBoxComponent name="Übersetzungskey"
                        value={editor.translationKey}
                        onChange={(value) => editorActions.updateCategoryName(value)}
                        placeholder={"z.B. Bauch"}
                        isRequired={true}
                        isAutoFocus={true}
                        maxLength={20}
                        width={"50%"}
                    />
                </Grid>
                <POEditorLinkComponent projectName={getConfig().translation.project.measurements}/>
                <Grid item md={6}>
                    <DropdownComponent
                        name="Positionstyp"
                        data={BodyInformationTypeEnumValues}
                        isLoading={false}
                        isRequired={true}
                        onSelect={(type) => editorActions.updateCategoryType(type)}
                        displayedProperties={["text"]}
                        selectedValue={editor.type}
                    />
                </Grid>
                <Grid item md={6}>
                    {
                        editor.type.value === BodyInformationType.FreeText &&
                        <>
                            <DecimalInputComponent
                                name={"Textlänge"}
                                value={editor.freeTextLength}
                                minValue={0}
                                maxValue={1000}
                                onChange={(value) => editorActions.updateFreeTextLength(value)}
                                isDisabled={false}
                                isRequired={true}
                            />
                        </>
                    }
                </Grid>
                <Grid item>
                    <EditorDescriptionComponent>Handelt es sich um eine Pflichtangabe?</EditorDescriptionComponent>
                </Grid>
                <Grid item>
                    <FormControlLabel label={"Ja"}
                        control={
                            <Radio
                                checked={editor.isMandatory}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={() => editorActions.updateIsMandatory(true)}
                            />} />
                    <FormControlLabel label={"Nein"}
                        control={
                            <Radio
                                checked={!editor.isMandatory}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={() => editorActions.updateIsMandatory(false)}
                            />} />
                </Grid>
                <Grid item container direction={"column"} md={6} spacing={3}>
                    <Grid item>
                        <EditorDescriptionComponent>Welche Positionen sollen angezeigt werden?</EditorDescriptionComponent>
                    </Grid>
                    {
                        editor.entriesKeys.map((item, index) => {
                            return (
                                <Grid item md={6} direction={'row'}>
                                    <TextBoxComponent
                                        key={index}
                                        name="Übersetzungskey"
                                        value={item.translationKey}
                                        onChange={(value) => editorActions.updateTranslationKey(index, value)}
                                        placeholder={"z.B. Hängelaib"}
                                        isRequired={true}
                                        isAutoFocus={true}
                                        maxLength={20}
                                    />
                                    <TextBoxComponent
                                        key={index}
                                        name="Kürzel"
                                        value={item.shortKey}
                                        onChange={(value) => editorActions.updateShortKey(index, value)}
                                        placeholder={"VKZ"}
                                        isRequired={true}
                                        minLength={3}
                                        maxLength={3}
                                    />
                                    <IconButton
                                        style={{ outline: "none", marginLeft: "15px" }}
                                        onClick={() => editorActions.removeTranslationKey(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>

                            )
                        })
                    }
                    {
                        editor.entriesKeys.length > 0 && <POEditorLinkComponent projectName={getConfig().translation.project.measurements}/>
                    }
                </Grid>
                <Grid item
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    md={6}>
                    <ListButtonComponent style={{ width: '50%' }}
                        onClick={() => editorActions.addTranslationKey()}>
                        <AddIcon /> Zusätzliche Angabe hinzufügen
                    </ListButtonComponent>
                </Grid>
            </Grid>
        </>
    );
}
