import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionState } from './addition.model';
import {
    updateAddition,
    getAddition,
    initData,
    getBaseData,
    getAdditions,
    getCategories,
    getCountries,
    getAttributeCategories,
    getPositionCategories,
    getFootTypes,
    getMassPoints,
    getCharacteristicCategories,
    cancelSave,
    getMappedBaseData,
    getAdditionDefinition,
    saveAddition
} from './addition.thunks';
import {
    setAdditionDefinition,
    loadMainProductLines,
    loadBodyAreas,
    updateCanSaveAddition,
    filterCountries,
    moveCountriesFromUnselectedToSelected,
    moveCountriesFromSelectedToUnselected,
    moveAlreadySelectedCountries,
    removeCategoryFromAvailableCategories,
    fillAvailableAttributesToAttributeForm,
    filterAttributes,
    moveAttributeFromUnselectedToSelected,
    moveAttributeFromSelectedToUnselected,
    createAttributForms,
    moveGussetArticleTypeFromSelectedToUnselected,
    moveGussetArticleTypeFromUnselectedToSelected,
    moveGussetQualityFromSelectedToUnselected,
    moveGussetQualityFromUnselectedToSelected,
    sortMassPointCriteria,
    distinctMassPointCriteria,
    getNewConfig,
    recalculateAvailableQualitiesForPlaceholderConfigs,
    convertPlaceholderValuesToIntegers,
    extractAdditionDefinition,
    updateCanGetAddition,
} from './addition.reducer';
import { SideEnum, AdditionSideValues } from 'models/additions/addition_side.enum';
import { AdditionPlaceHolderTypeEnum, AdditionPlaceHolderTypeValues } from 'models/additions/addition_placeholder.enum';
import { EnumDescriptor } from 'models/enum_descriptor';
import { YesNoOptions } from 'models/boolean_radio_box.options';
import {
    PrintAreaEnumValues,
    OrderManagementEnumValues,
    AttributeForm,
    AvailableCategory,
    CompressionClassEnumValues,
    PrintAreaEnum,
    OrderManagementEnum,
    IIndexedFilter,
    IIndexedNumber,
    IIndexMasspointUpdate,
    IIndexMasspointDelete,
    IIndexMasspointValueUpdate,
    IPlaceholderValue,
    IPlaceholderQuality,
} from 'models/additions/addition_editor.model';
import { ICountry } from 'models/country/country';
import { IAdditionCategoryOverview } from 'models/addition_categories/addition_category_overview';
import { doesExist } from 'services/validation.service';
import { IAttributeFormSearchText, IAttributeSelection } from 'models/addition_categories/addition_category_create';
import { AdditionCompressionClassEnum } from 'models/additions/addition_compressionclass.enum';
import { ProductionTypeEnum, ProductionTypeValues } from 'models/additions/productiontype.enum';
import { MasspointTypeEnumValuesLookup } from 'models/masspoints/enums/masspoint_type.enum';
import { calculateDependentFields, calculateArticleTypeSelection, calculateNewValidityScope, calculateQualitiesSelection, createEditValidityScope, dependentPlaceholdersStillValid, isEditableValidityScopeValid } from 'additions/common/helpers/validityScope';
import { IIndexedIds } from 'check/policy/policy_adjust_ranges_by_masspoint/components/policy_adjust_ranges_by_masspoint_configuration.model';
import { LockTypeEnum, LockTypeEnumValues } from 'shared/components/selectionComponent/models/locktype.enum';
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from 'shared/components/selectionComponent/selectionList.helper';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';
import { getIAdditionOverviewDisplayName, getIAvailableFootTypeDisplayName } from 'shared/helpers/displayNames';
import { ISelectable } from 'shared/components/selectionComponent/models/selectable';
import { filterArticleTypes, filterQualities } from 'additions/common/helpers/filters';
import { QuantityCalculationModeEnum, QuantityCalculationModeValues } from 'models/additions/quantity_calculation_mode.enum';

const initialState: AdditionState = {
    loadedData: {
        additionInitial: {
            mappedBaseData: null,
            bodyAreas: [],
            mainProductLines: [],
            articleTypes: [],
            qualities: [],
            masspoints: [],
            categories: [],
            positionCategories: [],
            characteristicCategories: [],
            positioningOptions: YesNoOptions,
            characteristicCategoryOptions: YesNoOptions,
            sides: AdditionSideValues,
            printAreas: PrintAreaEnumValues,
            orderManagementOptions: OrderManagementEnumValues,
            automatedProductions: YesNoOptions,
            reorderOptions: YesNoOptions,
            isFullyKnitted: YesNoOptions,
            isRecipeValidOptions: YesNoOptions,
            isGussetOptions: YesNoOptions,
            isMultiplyOptions: YesNoOptions,
            compressionClass: CompressionClassEnumValues,
            productionTypes: ProductionTypeValues,
            lockCountryOptions: YesNoOptions,
            createOrderPositionOptions: YesNoOptions,
            additionScopeValidity: LockTypeEnumValues,
            calculateOrderPosition: YesNoOptions,
            placeholderTypes: AdditionPlaceHolderTypeValues,
            availableAttributeCategories: [],
        },
        loadedAdditions: [],
        loadedFootTypes: []
    },
    actualData: {
        editorMode: EditorModeEnum.Create,
        optionName: "",
        showERPDataLoader: true,
        additionEditor: {
            addition: {
                code: "",
                description: "",
                descriptionLong: "",
                hasDescription: false,
                option: "",
                quantityCode: 0,
                quantityText: "",
                quantityCalculationMode: QuantityCalculationModeValues.find(qc => qc.value === QuantityCalculationModeEnum.QuantityUnit),
                side: AdditionSideValues.find(x => x.value === SideEnum.None),
                createOrderPosition: YesNoOptions.find(x => x.value === true),
                calculateOrderPosition: YesNoOptions.find(x => x.value === true),
                reOrder: YesNoOptions.find(x => x.value === true),
                automaticProduction: YesNoOptions.find(x => x.value === true),
                printArea: PrintAreaEnumValues.find(x => x.value === 1),
                orderManagement: OrderManagementEnumValues.find(x => x.value === 1),
                isFullyKnitted: YesNoOptions.find(x => x.value === false),
                isRecipeValid: YesNoOptions.find(x => x.value === false),
                isGusset: YesNoOptions.find(x => x.value === false),
                isMultiply: YesNoOptions.find(x => x.value === false),
                compressionClass: CompressionClassEnumValues.find(x => x.value === AdditionCompressionClassEnum.None),
                productionType: ProductionTypeValues.find(x => x.value === ProductionTypeEnum.None),
                category: null,
                placeholder: {
                    defaultConfig: {
                        textInputPlaceholderVersion: null,
                        rangePlaceholderVersion: null,
                        twoRangePlaceholderVersion: null,
                    },
                    placeholderTemplate: AdditionPlaceHolderTypeValues[0],
                    qualityConfigs: [],
                    twoRangePlaceholderIsSideSpecific: true,
                    isInteger: false,
                },
                validityScopes: [],
                editableScope: null,
                editableScopeIndex: -1,
                scopeDeletingIndex: -1,
                additionGussets: [],
                countryLock: {
                    lockCountry: false,
                    countryLockType: LockTypeEnum.Allow,
                    countryLocks: [],
                },
                lockFootType: false,
                positionCode: "",
                maxPositions: null
            },
            additionExcludeCriteriaSelection: getEmptySelectionList(),
            positionCategorySelection: getEmptySelectionList(),
            selectedcharacteristicCategoryId: undefined,
            showDeleteValidityDialog: false,
            showErrorMessageDialog: false,
            showDismissValidityAreaDialog: false,
            errorMessageTitle: "",
            errorMessageDescription: "",
            selectedPositioningOption: YesNoOptions.find(x => x.value === false),
            selectedCharacteristicCategoryOption: YesNoOptions.find(x => x.value === false),
            selectedLockCountry: null,
            selectedCountries: {
                loadedCountries: [],
                selectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                },
                unSelectedList: {
                    searchText: "",
                    allItems: [],
                    filteredItems: []
                }
            },
            footTypeSelection: getEmptySelectionList(),
            categories: {
                availableCategories: [],
                attributeForms: []
            }
        },
    },
    query: {
        getAdditionDefinition: { status: "idle", canExecute: true },
        getAddition: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
        getBaseData: { status: "idle", canExecute: true },
        getMappedBaseData: { status: "idle", canExecute: true },
        getAdditions: { status: "idle", canExecute: true },
        getMassPoints: { status: "idle", canExecute: true },
        getCountries: { status: "idle", canExecute: true },
        getCategories: { status: "idle", canExecute: true },
        getAttributeCategories: { status: "idle", canExecute: true },
        getFootTypes: { status: "idle", canExecute: true },
        getPositionCategories: { status: "idle", canExecute: true },
        getCharacteristicCategories: { status: "idle", canExecute: true },
    },
    command: {
        saveAddition: { status: "idle", canExecute: false },
        updateAddition: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    },
    errorData: {
        additionDefinitionError: null
    },
}

export const additionEditSlice = createSlice({
    name: 'addition/edit',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
            state.errorData = initialState.errorData;
        },
        updateAdditionErrorCompleted: (state) => {
            state.command.updateAddition.status = "idle";
        },
        updateAdditionCompleted: (state) => {
            state.command.updateAddition.status = "idle";
        },
        saveAdditionErrorCompleted: (state) => {
            state.command.saveAddition.status = "idle";
        },
        saveAdditionCompleted: (state) => {
            state.command.saveAddition.status = "idle";
        },
        getAdditionErrorCompleted: (state) => {
            state.query.getAddition.status = "idle";
        },
        updateSide: (state, action: PayloadAction<EnumDescriptor<SideEnum>>) => {
            state.actualData.additionEditor.addition.side = action.payload;
            updateCanSaveAddition(state);
        },
        updateCategory: (state, action: PayloadAction<IAdditionCategoryOverview>) => {
            state.actualData.additionEditor.addition.category = action.payload;
        },
        updatePlaceHolderType: (state, action: PayloadAction<EnumDescriptor<AdditionPlaceHolderTypeEnum>>) => {
            state.actualData.additionEditor.addition.placeholder.placeholderTemplate = action.payload;
            updateCanSaveAddition(state);
        },
        updateQuantityCalculationMode:(state, action: PayloadAction<EnumDescriptor<QuantityCalculationModeEnum>>) => {
            state.actualData.additionEditor.addition.quantityCalculationMode = action.payload;
            updateCanSaveAddition(state);
        },
        addPlaceholderQualityConfig: (state) => {
            state.actualData.additionEditor.addition.placeholder.qualityConfigs.push({ config: getNewConfig() })
            recalculateAvailableQualitiesForPlaceholderConfigs(state);
            updateCanSaveAddition(state);
        },
        deletePlaceholderQualityConfig: (state, action: PayloadAction<number>) => {
            const qualityConfigs = state.actualData.additionEditor.addition.placeholder.qualityConfigs;
            state.actualData.additionEditor.addition.placeholder.qualityConfigs = [...qualityConfigs.slice(0, action.payload), ...qualityConfigs.slice(action.payload + 1)];
            recalculateAvailableQualitiesForPlaceholderConfigs(state);
            updateCanSaveAddition(state);
        },
        selectPlaceholderConfigQuality: (state, action: PayloadAction<IPlaceholderQuality>) => {
            const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
            config.quality = action.payload.quality;
            recalculateAvailableQualitiesForPlaceholderConfigs(state);
            updateCanSaveAddition(state);
        },
        updatePlaceholderTextLength: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.textInputPlaceholderVersion.textInputMaxLength = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.textInputPlaceholderVersion.textInputMaxLength = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateRangeMinimalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.rangePlaceholderVersion.minimalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.minimalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateRangeMaximalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.rangePlaceholderVersion.maximalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.rangePlaceholderVersion.maximalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateTwoRangeFirstMinimalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.twoRangePlaceholderVersion.firstMinimalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMinimalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateTwoRangeFirstMaximalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.twoRangePlaceholderVersion.firstMaximalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.firstMaximalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateTwoRangeSecondMinimalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.twoRangePlaceholderVersion.secondMinimalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMinimalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        updateTwoRangeSecondMaximalValue: (state, action: PayloadAction<IPlaceholderValue>) => {
            if (doesExist(action.payload.index)) {
                const config = state.actualData.additionEditor.addition.placeholder.qualityConfigs[action.payload.index];
                config.config.twoRangePlaceholderVersion.secondMaximalValue = action.payload.value;
            }
            else {
                state.actualData.additionEditor.addition.placeholder.defaultConfig.twoRangePlaceholderVersion.secondMaximalValue = action.payload.value;
            }
            updateCanSaveAddition(state);
        },
        toggleTwoRangePlaceholderIsSideSpecific: (state) => {
            state.actualData.additionEditor.addition.placeholder.twoRangePlaceholderIsSideSpecific = !state.actualData.additionEditor.addition.placeholder.twoRangePlaceholderIsSideSpecific;
        },
        toggleIsInteger: (state) => {
            state.actualData.additionEditor.addition.placeholder.isInteger = !state.actualData.additionEditor.addition.placeholder.isInteger;
            const isInteger = state.actualData.additionEditor.addition.placeholder.isInteger;
            if (isInteger) {
                convertPlaceholderValuesToIntegers(state.actualData.additionEditor);
            }
        },
        updatePrintArea: (state, action: PayloadAction<EnumDescriptor<PrintAreaEnum>>) => {
            state.actualData.additionEditor.addition.printArea = action.payload;
            updateCanSaveAddition(state);
        },
        updateOrderManagement: (state, action: PayloadAction<EnumDescriptor<OrderManagementEnum>>) => {
            state.actualData.additionEditor.addition.orderManagement = action.payload;
            updateCanSaveAddition(state);
        },
        updateAutomatedProduction: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.automaticProduction = action.payload;
            updateCanSaveAddition(state);
        },
        updateReorder: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.reOrder = action.payload;
            updateCanSaveAddition(state);
        },
        updatePositioningOptions: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.selectedPositioningOption = state.loadedData.additionInitial.lockCountryOptions.find(x => x.value === action.payload.value);
            updateCanSaveAddition(state);
        },
        updateLockCountry: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.selectedLockCountry = state.loadedData.additionInitial.lockCountryOptions.find(x => x.value === action.payload.value);
            state.actualData.additionEditor.addition.countryLock.lockCountry = action.payload.value;
            updateCanSaveAddition(state);
        },
        updateLockFootType: (state, action: PayloadAction<boolean>) => {
            state.actualData.additionEditor.addition.lockFootType = action.payload;
            updateCanSaveAddition(state);
        },
        updateFullyKnitted: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.isFullyKnitted = action.payload;
            updateCanSaveAddition(state);
        },
        updateCompressionClass: (state, action: PayloadAction<EnumDescriptor<AdditionCompressionClassEnum>>) => {
            state.actualData.additionEditor.addition.compressionClass = action.payload;
            updateCanSaveAddition(state);
        },
        updateProductionType: (state, action: PayloadAction<EnumDescriptor<ProductionTypeEnum>>) => {
            state.actualData.additionEditor.addition.productionType = action.payload;
            updateCanSaveAddition(state);
        },
        updateIsRecipeValid: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.isRecipeValid = action.payload;
            updateCanSaveAddition(state);
        },
        updateIsGusset: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.isGusset = action.payload;
            updateCanSaveAddition(state);
        },
        updateIsMultiply: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.isMultiply = action.payload;
            updateCanSaveAddition(state);
        },
        updateCreateOrderPosition: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.createOrderPosition = action.payload;
            updateCanSaveAddition(state);
        },
        updateCalculateOrderPosition: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.addition.calculateOrderPosition = action.payload;
            updateCanSaveAddition(state);
        },
        updateBodyArea: (state, action: PayloadAction<ISelectable>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.bodyArea = action.payload;
            editableScope.articleTypes = calculateArticleTypeSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
            updateCanSaveAddition(state)
        },
        updateMainProductLine: (state, action: PayloadAction<ISelectable>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.mainProductLine = action.payload;
            calculateDependentFields(editableScope, state.loadedData.additionInitial.mappedBaseData);
            updateCanSaveAddition(state);
        },
        deleteValidityScope: (state, action: PayloadAction<number>) => {
            state.actualData.additionEditor.addition.scopeDeletingIndex = action.payload;
            if (dependentPlaceholdersStillValid(state.actualData.additionEditor)) {
                state.actualData.additionEditor.showDeleteValidityDialog = true;
            }
            else {
                state.actualData.additionEditor.addition.scopeDeletingIndex = -1;
                state.actualData.additionEditor.showErrorMessageDialog = true;
                state.actualData.additionEditor.errorMessageTitle = "Fehler beim Löschen";
                state.actualData.additionEditor.errorMessageDescription = "Die Konfiguration kann nicht gelöscht werden, da eine Abhängigkeit zum Bereich Werte besteht";
            }
        },
        cancelDeleteValidityScope: (state) => {
            state.actualData.additionEditor.showDeleteValidityDialog = false;
            state.actualData.additionEditor.addition.scopeDeletingIndex = -1;
        },
        confirmDeleteValidityScope: (state) => {
            const deletingIndex = state.actualData.additionEditor.addition.scopeDeletingIndex;
            state.actualData.additionEditor.addition.validityScopes = state.actualData.additionEditor.addition.validityScopes.filter((x, index) => deletingIndex !== index);
            state.actualData.additionEditor.addition.scopeDeletingIndex = -1;
            state.actualData.additionEditor.showDeleteValidityDialog = false;
            recalculateAvailableQualitiesForPlaceholderConfigs(state);
            updateCanSaveAddition(state);
        },
        confirmError: (state) => {
            state.actualData.additionEditor.showErrorMessageDialog = false;
        },
        confirmDismissChanges: (state) => {
            state.actualData.additionEditor.addition.editableScope = null;
            state.actualData.additionEditor.addition.editableScopeIndex = -1;
            updateCanSaveAddition(state);
        },
        cancelDismissChanges: (state) => {
            state.actualData.additionEditor.showDismissValidityAreaDialog = false;
        },
        addValidityScope: (state) => {
            state.actualData.additionEditor.addition.editableScope = calculateNewValidityScope();
            updateCanSaveAddition(state);
        },
        editScopeConfiguration: (state, action: PayloadAction<number>) => {
            state.actualData.additionEditor.addition.editableScopeIndex = action.payload;
            state.actualData.additionEditor.addition.editableScope = createEditValidityScope(state.actualData.additionEditor.addition.validityScopes[action.payload]);
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.articleTypes = calculateArticleTypeSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
            filterSelectionList(editableScope.articleTypes.selectedList);
            filterSelectionList(editableScope.articleTypes.unSelectedList);
            filterSelectionList(editableScope.qualities.selectedList);
            filterSelectionList(editableScope.qualities.unSelectedList);
            updateCanSaveAddition(state);
        },
        applyEditableScope: (state) => {
            const editableValidityScopeValid = isEditableValidityScopeValid(state.actualData.additionEditor.addition.editableScope, state.loadedData.additionInitial.mappedBaseData);
            const placeholdersStillValid = dependentPlaceholdersStillValid(state.actualData.additionEditor);
            if (editableValidityScopeValid && placeholdersStillValid) {
                const addition = state.actualData.additionEditor.addition;
                if (addition.editableScopeIndex === -1) {
                    addition.validityScopes = [...addition.validityScopes, addition.editableScope];
                }
                else {
                    addition.validityScopes = addition.validityScopes.map((aS, index) =>
                        index === addition.editableScopeIndex ? addition.editableScope : aS
                    );
                }
                addition.editableScope = null;
                addition.editableScopeIndex = -1;
                recalculateAvailableQualitiesForPlaceholderConfigs(state);
                updateCanSaveAddition(state);
            }
            else {
                state.actualData.additionEditor.showErrorMessageDialog = true;
                state.actualData.additionEditor.errorMessageTitle = "Fehler beim Übernehmen";
                if (!placeholdersStillValid) {
                    state.actualData.additionEditor.errorMessageDescription = "Die Konfiguration kann nicht übernommen werden, da eine Abhängigkeit zum Bereich Werte besteht.";
                }
                else {
                    state.actualData.additionEditor.errorMessageDescription = "Die Konfiguration kann nicht übernommen werden, da sie ungültige Werte enthält.";
                }
            }
        },
        cancelEditableScope: (state) => {
            state.actualData.additionEditor.showDismissValidityAreaDialog = true;
        },
        getAdditionDefinitionErrorCompleted: (state) => {
            state.query.getAdditionDefinition.status = "idle";
        },
        addGussetDefinition: (state) => {
            const index = state.actualData.additionEditor.addition.additionGussets.length;
            state.actualData.additionEditor.addition.additionGussets.push({
                priority: null,
                additionGussetArticleTypes: {
                    selectedList: {
                        searchText: "",
                        allItems: [],
                        filteredItems: [],
                    },
                    unSelectedList: {
                        searchText: "",
                        allItems: state.loadedData.additionInitial.articleTypes,
                        filteredItems: state.loadedData.additionInitial.articleTypes,
                    },
                },
                additionGussetQualities: {
                    selectedList: {
                        searchText: "",
                        allItems: [],
                        filteredItems: [],
                    },
                    unSelectedList: {
                        searchText: "",
                        allItems: state.loadedData.additionInitial.qualities,
                        filteredItems: state.loadedData.additionInitial.qualities,
                    },
                },
                additionGussetMassPoints: [],
                availableGussetMassPointModels: state.loadedData.additionInitial.masspoints,
                index: index
            });
            filterArticleTypes(state.actualData.additionEditor.addition.additionGussets[index].additionGussetArticleTypes.unSelectedList);
            filterQualities(state.actualData.additionEditor.addition.additionGussets[index].additionGussetQualities.unSelectedList);
            updateCanSaveAddition(state);
        },
        deleteGussetDefinition: (state, action: PayloadAction<number>) => {
            const newGussetDefinitions = [];
            let index = 0;
            state.actualData.additionEditor.addition.additionGussets.forEach(gusset => {
                if (gusset.index !== action.payload) {
                    gusset.index = index;
                    index = index + 1;
                    newGussetDefinitions.push(gusset);
                }
            });
            state.actualData.additionEditor.addition.additionGussets = newGussetDefinitions;
            updateCanSaveAddition(state);
        },
        filterSelectedGussetQualities: (state, action: PayloadAction<IIndexedFilter>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetQualities.selectedList.searchText = action.payload.searchText;
            filterQualities(state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetQualities.selectedList);
        },
        filterUnselectedGussetQualities: (state, action: PayloadAction<IIndexedFilter>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetQualities.unSelectedList.searchText = action.payload.searchText;
            filterQualities(state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetQualities.unSelectedList);
        },
        selectGussetQualities: (state, action: PayloadAction<IIndexedIds>) => {
            moveGussetQualityFromUnselectedToSelected(state, action.payload);
            updateCanSaveAddition(state);
        },
        unselectGussetQualities: (state, action: PayloadAction<IIndexedIds>) => {
            moveGussetQualityFromSelectedToUnselected(state, action.payload);
            updateCanSaveAddition(state);
        },
        filterSelectedGussetArticleTypes: (state, action: PayloadAction<IIndexedFilter>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetArticleTypes.selectedList.searchText = action.payload.searchText;
            filterArticleTypes(state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetArticleTypes.selectedList);
        },
        filterUnselectedGussetArticleTypes: (state, action: PayloadAction<IIndexedFilter>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetArticleTypes.unSelectedList.searchText = action.payload.searchText;
            filterArticleTypes(state.actualData.additionEditor.addition.additionGussets[action.payload.index].additionGussetArticleTypes.unSelectedList);
        },
        selectGussetArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            moveGussetArticleTypeFromUnselectedToSelected(state, action.payload);
            updateCanSaveAddition(state);
        },
        unselectGussetArticleTypes: (state, action: PayloadAction<IIndexedIds>) => {
            moveGussetArticleTypeFromSelectedToUnselected(state, action.payload);
            updateCanSaveAddition(state);
        },
        updateGussetPriority: (state, action: PayloadAction<IIndexedNumber>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].priority = action.payload.value;
            updateCanSaveAddition(state);
        },
        addGussetMasspoint: (state, action: PayloadAction<number>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload].additionGussetMassPoints.push(
                {
                    displayName: null,
                    name: null,
                    type: null,
                    bodyArea: null,
                    bodyAreaId: null,
                    maximalValue: null,
                    minimalValue: null
                }
            );
            updateCanSaveAddition(state);
        },
        updateGussetMasspoint: (state, action: PayloadAction<IIndexMasspointUpdate>) => {
            const gussetDefinition = state.actualData.additionEditor.addition.additionGussets[action.payload.index];
            const masspointUpdate = action.payload;
            const newSelectedIndex = gussetDefinition.availableGussetMassPointModels.findIndex(x => x.displayName === masspointUpdate.masspoint.displayName);
            const oldSelectedIndex = gussetDefinition.additionGussetMassPoints.findIndex(x => x.displayName === masspointUpdate.targetMasspoint.displayName);

            const newSelectedMasspoint = gussetDefinition.availableGussetMassPointModels[newSelectedIndex];
            const oldSelectedMasspoint = gussetDefinition.additionGussetMassPoints[oldSelectedIndex];

            newSelectedMasspoint.minimalValue = masspointUpdate.targetMasspoint.minimalValue;
            newSelectedMasspoint.maximalValue = masspointUpdate.targetMasspoint.maximalValue;
            gussetDefinition.additionGussetMassPoints.splice(oldSelectedIndex, 1, newSelectedMasspoint);

            if (oldSelectedMasspoint.displayName != null) {
                oldSelectedMasspoint.minimalValue = null;
                oldSelectedMasspoint.maximalValue = null;
                gussetDefinition.availableGussetMassPointModels.splice(newSelectedIndex, 1, oldSelectedMasspoint);
            } else {
                gussetDefinition.availableGussetMassPointModels.splice(newSelectedIndex, 1);
            }

            state.actualData.additionEditor.addition.additionGussets[action.payload.index].availableGussetMassPointModels = state.actualData.additionEditor.addition.additionGussets[action.payload.index].availableGussetMassPointModels.sort(sortMassPointCriteria);
            updateCanSaveAddition(state);
        },
        deleteGussetMasspoint: (state, action: PayloadAction<IIndexMasspointDelete>) => {
            const gussetDefinition = state.actualData.additionEditor.addition.additionGussets[action.payload.index];
            const masspoint = action.payload.masspoint;
            const oldSelectedIndex = gussetDefinition.additionGussetMassPoints.findIndex(x => x.displayName === masspoint.displayName);
            gussetDefinition.additionGussetMassPoints.splice(oldSelectedIndex, 1);
            if (masspoint.displayName != null) {
                gussetDefinition.availableGussetMassPointModels.push(masspoint);
            }
            state.actualData.additionEditor.addition.additionGussets[action.payload.index].availableGussetMassPointModels = state.actualData.additionEditor.addition.additionGussets[action.payload.index].availableGussetMassPointModels.sort(sortMassPointCriteria);
            updateCanSaveAddition(state);
        },
        updateGussetRangeMinimalValue: (state, action: PayloadAction<IIndexMasspointValueUpdate>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index]
                .additionGussetMassPoints.find(x => x.displayName === action.payload.masspoint.displayName).minimalValue = action.payload.value;
            updateCanSaveAddition(state);
        },
        updateGussetRangeMaximalValue: (state, action: PayloadAction<IIndexMasspointValueUpdate>) => {
            state.actualData.additionEditor.addition.additionGussets[action.payload.index]
                .additionGussetMassPoints.find(x => x.displayName === action.payload.masspoint.displayName).maximalValue = action.payload.value;
            updateCanSaveAddition(state);
        },
        selectAdditionExcludeCriteria: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.additionEditor.additionExcludeCriteriaSelection, action.payload);
            updateCanSaveAddition(state);
        },
        unselectAdditionExcludeCriteria: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.additionEditor.additionExcludeCriteriaSelection, action.payload);
            updateCanSaveAddition(state);
        },
        filterSelectedAdditionExcludeCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.additionExcludeCriteriaSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.additionExcludeCriteriaSelection.selectedList);
            updateCanSaveAddition(state);
        },
        filterUnselectedAdditionExcludeCriteria: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.additionExcludeCriteriaSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.additionExcludeCriteriaSelection.unSelectedList);
            updateCanSaveAddition(state);
        },
        filterSelectedPositionCategories: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.positionCategorySelection.selectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.positionCategorySelection.selectedList);
            updateCanSaveAddition(state);
        },
        filterUnselectedPositionCategories: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.positionCategorySelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.positionCategorySelection.unSelectedList);
            updateCanSaveAddition(state);
        },
        selectPositionCategories: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.additionEditor.positionCategorySelection, action.payload);
            updateCanSaveAddition(state);
        },
        unselectPositionCategories: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.additionEditor.positionCategorySelection, action.payload);
            updateCanSaveAddition(state);
        },
        filterUnselectedCountries: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.selectedCountries.unSelectedList.searchText = action.payload;
            filterCountries(state.actualData.additionEditor.selectedCountries.unSelectedList);
            updateCanSaveAddition(state);
        },
        filterSelectedCountries: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.selectedCountries.selectedList.searchText = action.payload;
            filterCountries(state.actualData.additionEditor.selectedCountries.selectedList);
            updateCanSaveAddition(state);
        },
        filterUnselectedFootType: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.footTypeSelection.unSelectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.footTypeSelection.unSelectedList);
            updateCanSaveAddition(state);
        },
        filterSelectedFootType: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.footTypeSelection.selectedList.searchText = action.payload;
            filterSelectionList(state.actualData.additionEditor.footTypeSelection.selectedList);
            updateCanSaveAddition(state);
        },
        selectCountries: (state, action: PayloadAction<ICountry[]>) => {
            moveCountriesFromUnselectedToSelected(state, action.payload);
            updateCanSaveAddition(state);
        },
        unselectCountries: (state, action: PayloadAction<ICountry[]>) => {
            moveCountriesFromSelectedToUnselected(state, action.payload);
            updateCanSaveAddition(state);
        },
        allowCountry: (state) => {
            state.actualData.additionEditor.addition.countryLock.countryLockType = LockTypeEnum.Allow;
            updateCanSaveAddition(state);
        },
        denyCountry: (state) => {
            state.actualData.additionEditor.addition.countryLock.countryLockType = LockTypeEnum.Deny;
            updateCanSaveAddition(state);
        },
        allowFootType: (state) => {
            state.actualData.additionEditor.footTypeSelection.lockType = LockTypeEnum.Allow;
            updateCanSaveAddition(state);
        },
        denyFootType: (state) => {
            state.actualData.additionEditor.footTypeSelection.lockType = LockTypeEnum.Deny;
            updateCanSaveAddition(state);
        },
        selectFootTypes: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.additionEditor.footTypeSelection, action.payload);
            updateCanSaveAddition(state);
        },
        unselectFootTypes: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.additionEditor.footTypeSelection, action.payload);
            updateCanSaveAddition(state);
        },
        addAttributeForm: (state) => {
            const attributeForm: AttributeForm = {
                isLocked: false,
                selectedCategory: undefined,
                selectedList: {
                    allItems: [],
                    filteredItems: [],
                    searchText: "",
                },
                unSelectedList: {
                    allItems: [],
                    filteredItems: [],
                    searchText: "",
                },
            };
            state.actualData.additionEditor.categories.attributeForms.push(attributeForm)
        },
        selectCategory: (state, action: PayloadAction<AvailableCategory>) => {
            const emptyAttributeForm = state.actualData.additionEditor.categories.attributeForms.find(x => x.selectedCategory == undefined);
            const selectedCategory = action.payload;
            emptyAttributeForm.selectedCategory = selectedCategory
            removeCategoryFromAvailableCategories(state, selectedCategory);
            fillAvailableAttributesToAttributeForm(emptyAttributeForm, state.loadedData.additionInitial.availableAttributeCategories);
        },
        deleteAttributeForm: (state, action: PayloadAction<AttributeForm>) => {
            if (action.payload.selectedCategory != undefined) {
                const index = state.actualData.additionEditor.categories.attributeForms.findIndex(x => x.selectedCategory.id === action.payload.selectedCategory.id);
                const category = state.actualData.additionEditor.categories.attributeForms[index].selectedCategory;
                if (category != undefined) {
                    state.actualData.additionEditor.categories.availableCategories.push(category);
                }
                state.actualData.additionEditor.categories.attributeForms.splice(index, 1);
            } else {
                const index = state.actualData.additionEditor.categories.attributeForms.findIndex(x => x.selectedCategory == undefined);
                state.actualData.additionEditor.categories.attributeForms.splice(index, 1);
            }
        },
        filterSelectedAttributes: (state, action: PayloadAction<IAttributeFormSearchText>) => {
            const formSearch = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formSearch.formIndex];
            attributeForm.selectedList.searchText = formSearch.searchText;
            filterAttributes(attributeForm.selectedList);
        },
        filterUnselectedAttributes: (state, action: PayloadAction<IAttributeFormSearchText>) => {
            const formSearch = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formSearch.formIndex];
            attributeForm.unSelectedList.searchText = formSearch.searchText;
            filterAttributes(attributeForm.unSelectedList);
        },
        selectAttributes: (state, action: PayloadAction<IAttributeSelection>) => {
            const formSearch = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formSearch.formIndex];
            moveAttributeFromUnselectedToSelected(attributeForm, formSearch.items);
        },
        unselectAttributes: (state, action: PayloadAction<IAttributeSelection>) => {
            const formSearch = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formSearch.formIndex];

            moveAttributeFromSelectedToUnselected(attributeForm, formSearch.items);
        },
        allowCategory: (state, action: PayloadAction<number>) => {
            const formIndex = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formIndex];
            attributeForm.isLocked = false;
        },
        denyCategory: (state, action: PayloadAction<number>) => {
            const formIndex = action.payload;
            const attributeForm = state.actualData.additionEditor.categories.attributeForms[formIndex];
            attributeForm.isLocked = true;
        },
        filterSelectedArticleType: (state, action: PayloadAction<string>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.articleTypes.selectedList.searchText = action.payload;
            filterSelectionList(editableScope.articleTypes.selectedList);
        },
        filterUnselectedArticleType: (state, action: PayloadAction<string>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.articleTypes.unSelectedList.searchText = action.payload;
            filterSelectionList(editableScope.articleTypes.unSelectedList);
        },
        filterSelectedQuality: (state, action: PayloadAction<string>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.qualities.selectedList.searchText = action.payload;
            filterSelectionList(editableScope.qualities.selectedList);
        },
        filterUnselectedQuality: (state, action: PayloadAction<string>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.qualities.unSelectedList.searchText = action.payload;
            filterSelectionList(editableScope.qualities.unSelectedList);
        },
        selectArticleTypes: (state, action: PayloadAction<number[]>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            moveFromUnselectedToSelected(editableScope.articleTypes, action.payload);
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
        },
        unselectArticleTypes: (state, action: PayloadAction<number[]>) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            moveFromSelectedToUnselected(editableScope.articleTypes, action.payload);
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
        },
        selectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.actualData.additionEditor.addition.editableScope.qualities, action.payload);
        },
        unselectQualities: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.actualData.additionEditor.addition.editableScope.qualities, action.payload);
        },
        allowArticleType: (state) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.articleTypes.lockType = LockTypeEnum.Allow;
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
        },
        allowQuality: (state) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.qualities.lockType = LockTypeEnum.Allow;
        },
        denyArticleType: (state) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.articleTypes.lockType = LockTypeEnum.Deny;
            editableScope.qualities = calculateQualitiesSelection(editableScope, state.loadedData.additionInitial.mappedBaseData);
        },
        denyQuality: (state) => {
            const editableScope = state.actualData.additionEditor.addition.editableScope;
            editableScope.qualities.lockType = LockTypeEnum.Deny;
        },
        updatePositionCode: (state, action: PayloadAction<string>) => {
            state.actualData.additionEditor.addition.positionCode = action.payload;
            updateCanSaveAddition(state);
        },
        selectCharacteristicCategory: (state, action: PayloadAction<number>) => {
            state.actualData.additionEditor.selectedcharacteristicCategoryId = action.payload;
            updateCanSaveAddition(state);
        },
        updateCharacteristicCategoryOptions: (state, action: PayloadAction<EnumDescriptor<boolean>>) => {
            state.actualData.additionEditor.selectedCharacteristicCategoryOption = action.payload;
            updateCanSaveAddition(state);
        },
        updateOptionName: (state, action: PayloadAction<string>) => {
            state.actualData.optionName = action.payload;
            state.errorData.additionDefinitionError = null;
            updateCanGetAddition(state);
        },
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.actualData.editorMode = action.payload;
        },    
        setShowERPDataLoader: (state, action: PayloadAction<boolean>) => {
            state.actualData.showERPDataLoader = action.payload;
        },    
        updateMaxPositions: (state, action: PayloadAction<number>) => {
            state.actualData.additionEditor.addition.maxPositions = action.payload;
        },
    }, extraReducers: (builder) => {
        // getAddition
        builder.addCase(getAddition.pending, (state, action) => {
            state.query.getAddition.status = "pending"
            state.query.getAddition.canExecute = false;
        }).addCase(getAddition.rejected, (state, action) => {
            state.query.getAddition.status = "error"
            state.query.getAddition.message = action.error.message;
        }).addCase(getAddition.fulfilled, (state, action) => {
            const addition = action.payload.getData();
            setAdditionDefinition(state, addition);
            state.query.getAddition.status = "success"
            state.actualData.additionEditor.additionExcludeCriteriaSelection = createSelectionList(state.loadedData.loadedAdditions, addition.additionExcludeVersions.map(x=> x.additionId), getIAdditionOverviewDisplayName);
            state.actualData.additionEditor.footTypeSelection = createSelectionList(state.loadedData.loadedFootTypes, addition.additionFootTypeVersions.map(x=> x.footTypeId), getIAvailableFootTypeDisplayName);
            state.actualData.additionEditor.footTypeSelection.lockType = addition.footTypeLockType == LockTypeEnum.Deny ? LockTypeEnum.Deny : LockTypeEnum.Allow;
            createAttributForms(state, addition.additionAttributeCategoryVersions);
            moveAlreadySelectedCountries(state, addition.additionCountryVersions);
            state.actualData.additionEditor.selectedcharacteristicCategoryId = addition.characteristicCategory?.characteristicCategoryId;
            recalculateAvailableQualitiesForPlaceholderConfigs(state);

        // getAdditionalDefinition
        }).addCase(getAdditionDefinition.pending, (state, action) => {
            state.query.getAdditionDefinition.status = "pending"
            state.query.getAdditionDefinition.canExecute = false;
        }).addCase(getAdditionDefinition.rejected, (state, action) => {
            state.query.getAdditionDefinition.status = "error"
            state.query.getAdditionDefinition.message = action.error.message;
            state.errorData.additionDefinitionError = {
                isVisible: true,
                text: "Die ERP-Artikelnummer konnte nicht abgerufen werden."
            };
        }).addCase(getAdditionDefinition.fulfilled, (state, action) => {
            state.query.getAdditionDefinition.status = "success"
            const additionDefinition = action.payload.getData();
            extractAdditionDefinition(state, additionDefinition);
            updateCanSaveAddition(state);

            // saveAddition
        }).addCase(saveAddition.pending, (state, action) => {
            state.command.saveAddition.status = 'pending'
            state.command.saveAddition.canExecute = false;
        }).addCase(saveAddition.rejected, (state, action) => {
            state.command.saveAddition.status = "error"
            state.command.saveAddition.canExecute = true;
            state.command.saveAddition.message = action.error.message;
        }).addCase(saveAddition.fulfilled, (state, action) => {
            state.command.saveAddition.status = "success"
            state.command.saveAddition.canExecute = true;

            // updateAddition
        }).addCase(updateAddition.pending, (state, action) => {
            state.command.updateAddition.status = 'pending'
            state.command.updateAddition.canExecute = false;
        }).addCase(updateAddition.rejected, (state, action) => {
            state.command.updateAddition.status = "error"
            state.command.updateAddition.canExecute = true;
            state.command.updateAddition.message = action.error.message;
        }).addCase(updateAddition.fulfilled, (state, action) => {
            state.command.updateAddition.status = "success"
            state.command.updateAddition.canExecute = true;

            // getBaseData
        }).addCase(getBaseData.pending, (state, action) => {
            state.query.getBaseData.status = 'pending'
            state.query.getBaseData.canExecute = false;
        }).addCase(getBaseData.rejected, (state, action) => {
            state.query.getBaseData.status = "error"
            state.query.getBaseData.canExecute = true;
            state.query.getBaseData.message = action.error.message;
        }).addCase(getBaseData.fulfilled, (state, action) => {
            state.query.getBaseData.status = "success"
            state.query.getBaseData.canExecute = true;
            const baseData = action.payload.getData();
            state.loadedData.additionInitial.bodyAreas = loadBodyAreas(baseData.availableBodyAreas);
            state.loadedData.additionInitial.mainProductLines = loadMainProductLines(baseData.availableMainProductLines);
            state.loadedData.additionInitial.articleTypes = baseData.availableArticleTypes;
            state.loadedData.additionInitial.qualities = baseData.availableQualities;

            // getMappedBaseData
        }).addCase(getMappedBaseData.pending, (state, action) => {
            state.query.getMappedBaseData.status = 'pending';
            state.query.getMappedBaseData.canExecute = false;
        }).addCase(getMappedBaseData.rejected, (state, action) => {
            state.query.getMappedBaseData.status = "error";
            state.query.getMappedBaseData.canExecute = true;
            state.query.getMappedBaseData.message = action.error.message;
        }).addCase(getMappedBaseData.fulfilled, (state, action) => {
            state.query.getMappedBaseData.status = "success";
            state.query.getMappedBaseData.canExecute = true;
            const mappedBaseData = action.payload.getData();
            state.loadedData.additionInitial.mappedBaseData = mappedBaseData;

            // getMassPoints
        }).addCase(getMassPoints.pending, (state, action) => {
            state.query.getMassPoints.status = 'pending'
        }).addCase(getMassPoints.rejected, (state, action) => {
            state.query.getMassPoints.status = "error"
            state.query.getMassPoints.message = action.error.message;
        }).addCase(getMassPoints.fulfilled, (state, action) => {
            state.query.getMassPoints.status = "success"
            state.query.getMassPoints.canExecute = true;
            const massPoints = action.payload.getData();

            const massPointCriteria = massPoints.map(mp => {
                return {
                    displayName: `${mp.name} / ${MasspointTypeEnumValuesLookup(mp.massPointType)} / ${mp.bodyArea.name}`,
                    name: mp.name,
                    type: mp.massPointType,
                    bodyArea: mp.bodyArea,
                    minimalValue: null,
                    maximalValue: null,
                    bodyAreaId: mp.bodyArea.id
                }
            }).filter(distinctMassPointCriteria);

            state.loadedData.additionInitial.masspoints = massPointCriteria;

            // getAdditions
        }).addCase(getAdditions.pending, (state, action) => {
            state.query.getAdditions.status = 'pending'
            state.query.getAdditions.canExecute = false;
        }).addCase(getAdditions.rejected, (state, action) => {
            state.query.getAdditions.status = "error"
            state.query.getAdditions.canExecute = true;
            state.query.getAdditions.message = action.error.message;
        }).addCase(getAdditions.fulfilled, (state, action) => {
            state.query.getAdditions.status = "success"
            state.query.getAdditions.canExecute = true;
            const additionsToExclude = action.payload.getData();
            state.loadedData.loadedAdditions = additionsToExclude;
            state.actualData.additionEditor.additionExcludeCriteriaSelection = createSelectionList(additionsToExclude, [], getIAdditionOverviewDisplayName);

            // getCategories
        }).addCase(getCategories.pending, (state, action) => {
            state.query.getCategories.status = 'pending'
            state.query.getCategories.canExecute = false;
        }).addCase(getCategories.rejected, (state, action) => {
            state.query.getCategories.status = "error"
            state.query.getCategories.canExecute = true;
            state.query.getCategories.message = action.error.message;
        }).addCase(getCategories.fulfilled, (state, action) => {
            state.query.getCategories.status = "success"
            state.query.getCategories.canExecute = true;
            const loadedCategories = action.payload.getData();
            state.loadedData.additionInitial.categories = loadedCategories.map(category => {
                return {
                    categoryText: doesExist(category.categoryText) ? category.categoryText : category.categoryKey,
                    categoryKey: category.categoryKey,
                    hasTranslation: category.hasTranslation,
                    id: category.id,
                }
            });

            // initData
        }).addCase(initData.pending, (state, action) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state, action) => {
            state.query.initData.status = "success"
            filterSelectionList(state.actualData.additionEditor.positionCategorySelection.selectedList);
            filterSelectionList(state.actualData.additionEditor.positionCategorySelection.unSelectedList);
            state.query.initData.canExecute = true;
            updateCanSaveAddition(state);

            // getCountries
        }).addCase(getCountries.pending, (state, action) => {
            state.query.getCountries.status = 'pending'
            state.query.getCountries.canExecute = false;
        }).addCase(getCountries.rejected, (state, action) => {
            state.query.getCountries.status = "error"
            state.query.getCountries.canExecute = true;
            state.query.getCountries.message = action.error.message;
        }).addCase(getCountries.fulfilled, (state, action) => {
            state.query.getCountries.status = "success"
            state.query.getCountries.canExecute = true;
            const loadedCountries = action.payload.getData();
            state.actualData.additionEditor.selectedCountries.loadedCountries = loadedCountries;
            state.actualData.additionEditor.selectedCountries.unSelectedList.allItems = loadedCountries;
            filterCountries(state.actualData.additionEditor.selectedCountries.unSelectedList);

            //Attribute Categories  
        }).addCase(getAttributeCategories.pending, (state, action) => {
            state.query.getAttributeCategories.status = "pending"
            state.query.getAttributeCategories.canExecute = false;
        }).addCase(getAttributeCategories.rejected, (state, action) => {
            state.query.getAttributeCategories.status = "error"
            state.query.getAttributeCategories.canExecute = true;
            state.query.getAttributeCategories.message = action.error.message;
        }).addCase(getAttributeCategories.fulfilled, (state, action) => {
            state.query.getAttributeCategories.status = "success"
            state.query.getAttributeCategories.canExecute = true;
            const loadedCategories = action.payload.getData();
            state.loadedData.additionInitial.availableAttributeCategories = loadedCategories.map(category => {
                return {
                    id: category.id,
                    name: category.name,
                    erpId: category.erpId,
                    availableAdditionAttributes: category.availableAdditionAttributes
                }
            });
            state.actualData.additionEditor.categories.availableCategories = loadedCategories.map(category => {
                return {
                    id: category.id,
                    name: category.name,
                    erpId: category.erpId,
                }
            });

            //getFootTypes
        }).addCase(getFootTypes.pending, (state, action) => {
            state.query.getFootTypes.status = 'pending'
            state.query.getFootTypes.canExecute = false;
        }).addCase(getFootTypes.rejected, (state, action) => {
            state.query.getFootTypes.status = "error"
            state.query.getFootTypes.canExecute = true;
            state.query.getFootTypes.message = action.error.message;
        }).addCase(getFootTypes.fulfilled, (state, action) => {
            state.query.getFootTypes.status = "success"
            state.query.getFootTypes.canExecute = true;
            const loadedFootTypes = action.payload.getData();
            state.loadedData.loadedFootTypes = action.payload.getData();
            state.actualData.additionEditor.footTypeSelection = createSelectionList(loadedFootTypes, [], getIAvailableFootTypeDisplayName);
            state.actualData.additionEditor.footTypeSelection.lockType = LockTypeEnum.Allow;
            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;


            // getPositionCategories
        }).addCase(getPositionCategories.pending, (state, action) => {
            state.query.getPositionCategories.status = "pending"
            state.query.getPositionCategories.canExecute = false;
        }).addCase(getPositionCategories.rejected, (state, action) => {
            state.query.getPositionCategories.status = "error"
            state.query.getPositionCategories.message = action.error.message;
        }).addCase(getPositionCategories.fulfilled, (state, action) => {
            state.query.getPositionCategories.status = "success"
            const positionCategories = action.payload.getData();
            state.loadedData.additionInitial.positionCategories = positionCategories;
            state.actualData.additionEditor.positionCategorySelection.unSelectedList.allItems = positionCategories.map(x => {
                return {
                    displayName: x.name,
                    id: x.id
                }
            });
            filterSelectionList(state.actualData.additionEditor.positionCategorySelection.unSelectedList);

            //getCharacteristicCategories
        }).addCase(getCharacteristicCategories.pending, (state, action) => {
            state.query.getCharacteristicCategories.status = 'pending'
            state.query.getCharacteristicCategories.canExecute = false;
        }).addCase(getCharacteristicCategories.rejected, (state, action) => {
            state.query.getCharacteristicCategories.status = "error"
            state.query.getCharacteristicCategories.canExecute = true;
            state.query.getCharacteristicCategories.message = action.error.message;
        }).addCase(getCharacteristicCategories.fulfilled, (state, action) => {
            state.query.getCharacteristicCategories.status = "success"
            state.query.getCharacteristicCategories.canExecute = true;

            const loadedCharacteristicCategories = action.payload.getData();

            state.loadedData.additionInitial.characteristicCategories = loadedCharacteristicCategories;
        })
    }
})

export const {
    resetState,
    addGussetDefinition,
    getAdditionDefinitionErrorCompleted,
    filterSelectedGussetQualities,
    filterUnselectedGussetQualities,
    selectGussetQualities,
    unselectGussetQualities,
    filterSelectedGussetArticleTypes,
    filterUnselectedGussetArticleTypes,
    selectGussetArticleTypes,
    unselectGussetArticleTypes,
    deleteGussetDefinition,
    addGussetMasspoint,
    updateGussetMasspoint,
    deleteGussetMasspoint,
    updateGussetRangeMinimalValue,
    updateGussetRangeMaximalValue,
    selectCharacteristicCategory,
    updateCharacteristicCategoryOptions,
    saveAdditionErrorCompleted,
    saveAdditionCompleted,   
    updateAdditionErrorCompleted,
    updateAdditionCompleted,
    updateSide,
    updateCategory,
    updatePrintArea,
    updateOrderManagement,
    updateAutomatedProduction,
    updateReorder,
    updateCreateOrderPosition,
    updateCalculateOrderPosition,
    updateBodyArea,
    updateGussetPriority,
    updateMainProductLine,
    updatePlaceHolderType,
    addPlaceholderQualityConfig,
    deletePlaceholderQualityConfig,
    selectPlaceholderConfigQuality,
    updatePlaceholderTextLength,
    updateRangeMinimalValue,
    updateRangeMaximalValue,
    updateTwoRangeFirstMinimalValue,
    updateTwoRangeFirstMaximalValue,
    updateTwoRangeSecondMinimalValue,
    updateTwoRangeSecondMaximalValue,
    toggleTwoRangePlaceholderIsSideSpecific,
    toggleIsInteger,
    updatePositioningOptions,
    deleteValidityScope,
    cancelDeleteValidityScope,
    confirmDeleteValidityScope,
    confirmError,
    cancelDismissChanges,
    confirmDismissChanges,
    editScopeConfiguration,
    addValidityScope,
    applyEditableScope,
    cancelEditableScope,
    getAdditionErrorCompleted,
    selectAdditionExcludeCriteria,
    unselectAdditionExcludeCriteria,
    filterSelectedAdditionExcludeCriteria,
    filterUnselectedAdditionExcludeCriteria,
    filterUnselectedCountries,
    filterSelectedCountries,
    filterSelectedPositionCategories,
    filterUnselectedPositionCategories,
    selectPositionCategories,
    unselectPositionCategories,
    selectCountries,
    unselectCountries,
    updateLockCountry,
    updateLockFootType,
    updateFullyKnitted,
    updateIsRecipeValid,
    updateIsGusset,
    updateCompressionClass,
    updateProductionType,
    updateQuantityCalculationMode,
    allowCountry,
    denyCountry,
    allowFootType,
    denyFootType,
    addAttributeForm,
    selectCategory,
    deleteAttributeForm,
    filterUnselectedAttributes,
    filterSelectedAttributes,
    selectAttributes,
    unselectAttributes,
    allowCategory,
    denyCategory,
    filterSelectedArticleType,
    filterUnselectedArticleType,
    selectArticleTypes,
    unselectArticleTypes,
    allowArticleType,
    denyArticleType,
    filterSelectedQuality,
    filterUnselectedQuality,
    selectQualities,
    unselectQualities,
    allowQuality,
    denyQuality,
    updatePositionCode,
    filterSelectedFootType,
    filterUnselectedFootType,
    selectFootTypes,
    unselectFootTypes,
    updateIsMultiply,
    updateOptionName,
    setEditorMode,
    setShowERPDataLoader,
    updateMaxPositions
} = additionEditSlice.actions

export default additionEditSlice.reducer