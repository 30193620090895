import React, { useEffect } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MassmaskStepperComponent, MassmaskWizardStepEnum } from "massmasks/components/massmask.stepper.component";
import { CancelButtonComponent, DropdownComponent, FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import { useAppDispatch, useAppSelector } from "app/hook";
import { useSnackbar } from 'notistack';
import { getProductLines, selectBodyArea, selectProductLine, createMassMask, fetchAllData, getBodyInformations } from './redux/massmask_common_info_create.thunks';
import { AppDispatch } from "app/redux_store";
import { addBodyInformation, moveDown, moveUp, removeBodyInformation, resetState, selectArticleType, selectBodyInformation, selectFootType, setHideFingersAndToes, setMassMaskIsEllipticalShapeKneeFunctionZone, setMassMaskIsPantyTopWithStandardDimensions, updateSideOrder } from "./redux/massmask_common_info_create.slice";
import * as style from "./massmask_common_information_create.style";
import { LoadingOverlayComponent, LoadingOverlayContainer } from '../../shared/components/loading_overlay/loading_overlay.component';
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, FormLabel, IconButton, Radio } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import AddIcon from '@mui/icons-material/Add';
import { ListButtonComponent } from "shared/components/buttons/list_button.component";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { SideOrderEnum } from "models/massmasks/side_order_enum";

export const MassmaskCommonInfoCreateComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.massmask_common_info_create);

    useEffect(() => {
        dispatch(fetchAllData());
        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.command.createMassMask.status === "error") {
        enqueueSnackbar(state.command.createMassMask.message, { variant: "error" });
        dispatch(resetState());
        dispatch(getProductLines());
        dispatch(getBodyInformations());
    }

    if (state.command.createMassMask.status === "success") {
        enqueueSnackbar("Allgemeine Informationen gespeichert", { variant: "success" })
        navigate(`/massmasks/massmask/${state.actualData.createdMassMaskId}/massdefinition`);
        dispatch(resetState());
    }

    const isSaving = state.command.createMassMask.status === "pending";

    const showAddBodyInformationButton = () => {
        return state.loadedData.bodyInformations.length > 0 &&
            state.actualData.bodyInformationSelection.length < state.loadedData.bodyInformations.length
    }

    const showBodyInformations = state.actualData.selectedArticleType != undefined
        && state.actualData.selectedArticleType != null;

    const renderBodyInformation = () => <>
        <Grid item>
            {
                state.actualData.bodyInformationSelection.map((value, index) => {
                    return (
                        <div key={index}>
                            <DropdownComponent
                                name="Zusätzliche Anweisungen"
                                data={state.loadedData.bodyInformations}
                                isLoading={false}
                                isRequired={true}
                                onSelect={(bodyInformation) => dispatch(selectBodyInformation({ id: bodyInformation.id, arrayPosition: index }))}
                                displayedProperties={["translationKeyForName"]}
                                selectedValue={state.loadedData.bodyInformations.find(x => x.id === value.id)}
                            />
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(moveUp(index))}>
                                <ArrowUpward />
                            </IconButton>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(moveDown(index))}>
                                <ArrowDownward />
                            </IconButton>
                            <IconButton
                                style={{ outline: "none", marginLeft: "15px" }}
                                onClick={() => dispatch(removeBodyInformation(index))}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                })
            }
        </Grid>
        {
            showAddBodyInformationButton() &&
            <Grid item
                style={{ marginTop: "15px", marginBottom: "15px" }}
                md={6}>
                <ListButtonComponent
                    onClick={() => dispatch(addBodyInformation())}>
                    <AddIcon /> Zusätzliche Angabe hinzufügen
                </ListButtonComponent>
            </Grid>
        }
    </>

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving ? <LoadingOverlayComponent /> : <></>}

                <MassmaskStepperComponent currentStep={MassmaskWizardStepEnum.CommonInformation} />
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={3}>
                    <Grid
                        item
                        container
                        direction="row" md={6}>
                        <Grid item>
                            <DropdownComponent
                                name="Hauptproduktlinie"
                                data={state.actualData.productLines}
                                isLoading={state.query.getProductLines.status === "pending"}
                                isRequired={true}
                                onSelect={(productLine) => dispatch(selectProductLine(productLine))}
                                displayedProperties={["name"]}
                                selectedValue={state.actualData.selectedProductLine}
                            />
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Körperregion"
                                data={state.actualData.bodyAreas}
                                isLoading={state.query.getBodyAreas.status === "pending"}
                                isRequired={true}
                                onSelect={(bodyArea) => dispatch(selectBodyArea(bodyArea))}
                                displayedProperties={["name"]}
                                selectedValue={state.actualData.selectedBodyArea}
                                isReadonly={state.actualData.isBodyAreaReadonly}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row" md={6}>
                        <Grid item>
                            <DropdownComponent
                                name="Artikelart"
                                data={state.actualData.articleTypes}
                                isLoading={state.query.getArticleTypes.status === "pending"}
                                isRequired={false}
                                onSelect={(articleType) => dispatch(selectArticleType(articleType))}
                                displayedProperties={["erpId", "name"]}
                                selectedValue={state.actualData.selectedArticleType}
                                isReadonly={state.actualData.isArticleTypeReadony}
                            />
                        </Grid>
                        <Grid item
                            alignContent="center">
                            <style.OrWrapper>oder</style.OrWrapper>
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Fußart"
                                data={state.actualData.footTypes}
                                isLoading={state.query.getFootTypes.status === "pending"}
                                isRequired={false}
                                onSelect={(footType) => dispatch(selectFootType(footType))}
                                displayedProperties={["externalIdentifier", "name"]}
                                selectedValue={state.actualData.selectedFootType}
                                isReadonly={state.actualData.isFootTypeReadony}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.isPantyTopWithStandardDimensions}
                                    onChange={(event, value) => { dispatch(setMassMaskIsPantyTopWithStandardDimensions(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Leibteil mit Standardmaßen"}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.isEllipticalShapeKneeFunctionZone}
                                    onChange={(event, value) => { dispatch(setMassMaskIsEllipticalShapeKneeFunctionZone(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Ellipsenform/Kniefunktionszone"}
                        />
                    </Grid>  
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.actualData?.hideFingersAndToes}
                                    onChange={(event, value) => { dispatch(setHideFingersAndToes(value)) }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={"Finger & Zehen ausblenden"}
                        />
                    </Grid>
                    <Grid item>
                        <EditorSectionHeaderComponent>Seitenreihenfolge</EditorSectionHeaderComponent>
                        <FormLabel></FormLabel>
                        <Grid item marginBottom={"20px"}>
                            <FormControlLabel label={"Links/Rechts"}
                                control={
                                    <Radio
                                        checked={state.actualData?.sideOrder == SideOrderEnum.LeftRight}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={() => dispatch(updateSideOrder(SideOrderEnum.LeftRight))}
                                    />} />
                            <FormControlLabel label={"Rechts/Links"}
                                control={
                                    <Radio
                                        checked={state.actualData?.sideOrder == SideOrderEnum.RightLeft}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={() => dispatch(updateSideOrder(SideOrderEnum.RightLeft))}
                                    />} />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    showBodyInformations &&
                    renderBodyInformation()
                }
                <style.FinalizeButtonComponentWrapper>
                    <FlexBox>
                        <Right>
                            <style.ButtonMargin>
                                <CancelButtonComponent />
                            </style.ButtonMargin>
                            <PrimaryButtonComponent
                                disabled={!state.command.createMassMask.canExecute}
                                onClick={() => dispatch(createMassMask())}
                            >
                                Weiter <ChevronRightIcon />
                            </PrimaryButtonComponent>
                        </Right>
                    </FlexBox>
                </style.FinalizeButtonComponentWrapper>
            </LoadingOverlayContainer>
        </>
    );
}