import Grid from "@mui/material/Grid";
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { getConfig } from "services/config.service";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { DropdownComponent, TextBoxComponent } from "../../shared/shared";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { initData, editPlausibilityCheckType } from './redux/type_edit.thunks';
import { updateName, toggleIsBestMatch, editPlausibilityCheckTypeCompleted, updateErrorMessageKey, updateWarningMessageKey, cancelCheck, resetState } from "./redux/type_edit.slice";
import { FormulaTemplateEnumValues } from '../../models/plausibility_check/enums/formula_template.enum';
import { EditorSectionHeaderComponent } from 'shared/components/editors/editor_header/editor_header.style'
import * as style from "./type_edit.style";
import { LinkComponent } from '../../shared/components/typography/link.component';
import { DisabledTextComponent } from '../../shared/components/typography/disabled_text.component';
import { doesExist } from '../../services/validation.service';
import { LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { LoadingOverlayComponent } from '../../shared/components/loading_overlay/loading_overlay.component';
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { POEditorLinkComponent } from "../../shared/components/editors/poeditor_link/poeditor_link.component";

export const EditPlausibilityCheckTypeComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.plausibilitychecktype_edit);
  const { id } = useParams();

  useEffect(() => {
    dispatch(initData(Number(id)));
    return () => {
      dispatch(resetState());
    }
  }, [dispatch, id]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const warningMessage = state.editedData.selectedWarningMessage?.message?.value;
  const errorMessage = state.editedData.selectedErrorMessage?.message?.value;

  if (state.query.initData.status === "error") {
    return <div>Fehler beim Laden der Daten</div>;
  }

  if (state.query.initData.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  if (state.command.editPlausibilityCheckType.status === "success") {
    enqueueSnackbar("Prüfungstyp aktualisiert", { variant: "success" });
    dispatch(editPlausibilityCheckTypeCompleted());
    navigate("/plausibilitycheck/type");
  }

  if (state.command.editPlausibilityCheckType.status === "error") {
    enqueueSnackbar("Fehler beim Aktualisieren des Prüfungstyps", { variant: "error" });
    dispatch(editPlausibilityCheckTypeCompleted());
  }

  if (state.command.cancel.status === "success") {
    dispatch(resetState());
    navigate("/plausibilitycheck/type");
}

  const isSaving = state.command.editPlausibilityCheckType.status === "pending";

  return (
    <>
      <LoadingOverlayContainer>
        {isSaving
          ? <LoadingOverlayComponent />
          : <></>
        }
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}>
          <Grid item md={12}>
            <TextBoxComponent
              name="Name Prüfungstyp"
              value={state.editedData.plausibilityCheckType.name}
              onChange={(value) => dispatch(updateName(value))}
              isRequired={true}
              maxLength={512}
              isAutoFocus={true}
            />
          </Grid>
          <Grid
            item md={12}>

            <DropdownComponent
              name="Regeltemplate"
              data={FormulaTemplateEnumValues}
              isReadonly={true}
              isLoading={false}
              onSelect={() => { }}
              displayedProperties={["text"]}
              selectedValue={FormulaTemplateEnumValues.find(x => x.value === state.editedData.plausibilityCheckType.formulaTemplate)}
            />
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >

            <Grid item md={12}>
              <EditorSectionHeaderComponent>Warn- & Fehlermeldungen</EditorSectionHeaderComponent>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}>
              <Grid item
                container
                md={6}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <DropdownComponent
                    name="Warnmeldung"
                    data={state.editedData.warningMessages}
                    isLoading={state.query.getCheckTypeMessages.status === "pending"}
                    isRequired={false}
                    onSelect={(selectedMessageKey) => dispatch(updateWarningMessageKey(selectedMessageKey))}
                    selectedValue={state.editedData.selectedWarningMessage}
                    displayedProperties={["displayValue"]}
                  />
                </Grid>
                {doesExist(warningMessage)
                  ? <Grid item>
                    <Typography>
                      {warningMessage}
                    </Typography>
                  </Grid>
                  : <></>
                }
              </Grid>

              <Grid item
                container
                md={6}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <DropdownComponent
                    name="Fehlermeldung"
                    data={state.editedData.errorMessages}
                    isLoading={state.query.getCheckTypeMessages.status === "pending"}
                    isRequired={false}
                    onSelect={(selectedMessageKey) => dispatch(updateErrorMessageKey(selectedMessageKey))}
                    selectedValue={state.editedData.selectedErrorMessage}
                    displayedProperties={["displayValue"]}
                  />
                </Grid>
                {doesExist(errorMessage)
                  ? <Grid item>
                    <Typography>
                      {errorMessage}
                    </Typography>
                  </Grid>
                  : <></>
                }
              </Grid>
              <POEditorLinkComponent projectName={getConfig().translation.project.measurements}/>
            </Grid>

          </Grid>

          <Grid item md={6}>
            <EditorSectionHeaderComponent>Weitere Einstellungen</EditorSectionHeaderComponent>
          </Grid>

          <Grid item md={6}>

            <FormControlLabel
              control={
                <Checkbox
                  id="isuniquerules-checktype-checkbox"
                  checked={state.actualData.plausibilityCheckType.isUniqueRules}
                  disabled={true}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="eindeutiges Regelwerk"
            />
            <DisabledTextComponent>
              verhindert, dass sich verschiedene Prüfungsregeln gegenseitig aufheben.
          </DisabledTextComponent>
          </Grid>
          <Grid item md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  id="isbestmatch-checktype-checkbox"
                  checked={state.editedData.plausibilityCheckType.isBestMatchEnabled}
                  onChange={() => dispatch(toggleIsBestMatch(!state.editedData.plausibilityCheckType.isBestMatchEnabled))}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Best-Match (empfohlen)"
            />
            <div>
              Best Match sucht und verwendet nur die Prüfungen, die am besten greifen. Mehr zur Funktionalität im <LinkComponent href="https://wiki.medi.de/x/XgDeAw" target="_blank" rel="noreferrer" >Handbuch</LinkComponent>.
          </div>
          </Grid>
          <EditorButtonComponent
              canExecute={state.command.editPlausibilityCheckType.canExecute}
              save={() => dispatch(editPlausibilityCheckType())}
              cancelSave={() => dispatch(cancelCheck())}
          />
        </Grid>
      </LoadingOverlayContainer>
    </>
  );
};
