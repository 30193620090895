import { useAppDispatch, useAppSelector } from 'app/hook';
import { AppDispatch } from 'app/redux_store';
import { cancelSaveAdditionPosition, handleSaveError, resetState, setShowConfirmCreateDialog } from "./redux/addition_position.slice";
import { createAdditionPosition, fetchAllData, updateAdditionPosition } from "./redux/addition_position.thunks";
import { useEffect } from 'react';
import { LoadingIndicatorComponent } from 'shared/components/loading_indicator/loading_indicator.component';
import { LoadingOverlayComponent, LoadingOverlayContainer } from 'shared/components/loading_overlay/loading_overlay.component';
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import { useDialog } from 'shared/contexts/dialog_context';
import { EditorModeEnum } from 'models/editors/editor_mode.enum';
import { Grid } from '@mui/material';
import { AdditionPositionInformationComponent } from './components/addition_position_basis_information';
import { EditorButtonComponent } from 'shared/components/editors/editor_button/editor_buttons.component';

export const AdditionPositionComponent = () => {
    const { id } = useParams();
    const state = useAppSelector((state) => state.addition_position);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dialog = useDialog();

    useEffect(() => {
        dispatch(fetchAllData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch, id]);

    if (state.query.fetchAllData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.createAdditionPosition.status === "success") {
        enqueueSnackbar("Zusatzposition gespeichert", { variant: "success" });
        navigate("/additions/additionposition");
        dispatch(resetState());
    }

    if (state.command.createAdditionPosition.status === "error") {
        enqueueSnackbar(state.command.createAdditionPosition.message, { variant: "error" });
        dispatch(handleSaveError());
    }

    if (state.command.createAdditionPosition.status === "warning") {
        enqueueSnackbar(state.command.createAdditionPosition.message, { variant: "warning" });
        dispatch(handleSaveError());
    }

    if (state.command.cancelUpdateAdditionPosition.status === "success") {
        navigate("/additions/additionposition");
        dispatch(resetState());
    }

    if (state.command.updateAdditionPosition.status === "success") {
        enqueueSnackbar("Zusatzposition gespeichert", { variant: "success" });
        navigate("/additions/additionposition");
        dispatch(resetState());
    }

    if (state.actualData.showConfirmCreateDialog) {
        dialog.show(
            "Zusatzposition erstellen",
            <div>Wurde die Produktion über die neue Zusatzposition informiert?</div>,
            [{
                title: "Nein",
                handleOption: () => {}
            }, {
                title: "Ja",
                handleOption: () => { dispatch(createAdditionPosition()) }
            }]);
        dispatch(setShowConfirmCreateDialog(false));
    }

    if (state.command.updateAdditionPosition.status === "error") {
        if (state.error.showErrorDialog) {
            if (state.error.errorCode === "422") {
                dialog.show(
                    "Zusatzposition konnte nicht gespeichert werden",
                    <div>{state.command.updateAdditionPosition.message}</div>,
                    [{
                        title: "Ok",
                        handleOption: () => { }
                    }]);
            } else if (state.error.errorCode === "400") {
                dialog.show(
                    "Zusatzposition konnte nicht gespeichert werden",
                    <div>{state.command.updateAdditionPosition.message} Ist die Änderung mit der Produktion abgesprochen?</div>,
                    [{
                        title: "Nein",
                        handleOption: () => { }
                    }, {
                        title: "Ja",
                        handleOption: () => { dispatch(updateAdditionPosition(true)) }
                    }]);
            }
        }
        else {
            enqueueSnackbar(state.command.updateAdditionPosition.message, { variant: "error" });
        }
        dispatch(handleSaveError());
    }

    if (state.command.updateAdditionPosition.status === "warning") {
        enqueueSnackbar(state.command.updateAdditionPosition.message, { variant: "warning" });
        dispatch(handleSaveError());
    }

    const isSaving = state.command.updateAdditionPosition.status === "pending" || state.command.createAdditionPosition.status === "pending";

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving && <LoadingOverlayComponent />}
                <Grid container
                    direction="column"
                    spacing={3}>
                    <Grid item>
                        <AdditionPositionInformationComponent />
                    </Grid>
                    <EditorButtonComponent
                        canExecute={state.actualData.canSave}
                        save={() => state.actualData.editorMode === EditorModeEnum.Edit ? dispatch(updateAdditionPosition(false)) : dispatch(setShowConfirmCreateDialog(true))}
                        cancelSave={() => dispatch(cancelSaveAdditionPosition())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};