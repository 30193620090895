import { createAsyncThunk } from '@reduxjs/toolkit';
import BaseDataService from 'services/base_data.service';
import { RootState } from '../../../app/redux_store';
import IAvailableBodyArea from '../../../models/available_basedata/available_body_area';
import IAvailableProductLine from '../../../models/available_basedata/available_product_line';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';
import { BodyInformationService } from 'services/bodyInformation.service';

export const fetchAllData = createAsyncThunk(
    'massMask/commonInfo/fetchAllData',
    async (_, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(getBodyInformations()),
            thunkApi.dispatch(getProductLines())
        ]);
    }
)

export const getProductLines = createAsyncThunk(
    'massMask/commonInfo/getProductLines',
    async (_) => {
        const response = await BaseDataService.getMainProductLines();
        handleResponseErrors(response, "Hauptproduktlinie");
        return response;
    }
)

export const getBodyInformations = createAsyncThunk(
    'massMask/commonInfo/getBodyInformations',
    async (_) => {
        const response = await BodyInformationService.getBodyInformations();
        handleResponseErrors(response, "Zusätzliche Angabe");
        return response;
    }
)

export const selectProductLine = createAsyncThunk(
    'massMask/commonInfo/selectProductLine',
    async (selectedProductLine: IAvailableProductLine, _) => {
        const response = await BaseDataService.getMatchingBodyAreas(selectedProductLine.id);
        handleResponseErrors(response, "Körperregion");
        return response;
    }
)

export const selectBodyAreaArticleType = createAsyncThunk(
    'massMask/commonInfo/selectBodyAreaArticleType',
    async (selectedBodyArea: IAvailableBodyArea, { getState }) => {
        const state = getState() as RootState;
        const data = state.massmask_common_info_create.actualData;
        const mainProductLineId = data.selectedProductLine?.id
        const response = await BaseDataService.getMatchingArticleTypes(mainProductLineId, selectedBodyArea.id)
        handleResponseErrors(response, "Artikelart");
        return response;
    }
)
export const selectBodyAreaFootType = createAsyncThunk(
    'massMask/commonInfo/selectBodyAreaFootType',
    async (_) => {
        const response = await BaseDataService.getFootTypes();
        handleResponseErrors(response, "Fußart");
        return response;
    }
)

export const selectBodyArea = createAsyncThunk(
    'massMask/commonInfo/selectBodyArea',
    async (selectedBodyArea: IAvailableBodyArea, thunkApi) => {
        await Promise.all([
            thunkApi.dispatch(selectBodyAreaArticleType(selectedBodyArea)),
            thunkApi.dispatch(selectBodyAreaFootType()),
        ]);
    }
)

export const createMassMask = createAsyncThunk(
    'massMask/commonInfo/createMassMask',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedData = state.massmask_common_info_create.actualData;

        const response = await MassMaskService.saveMassMask({
            mainProductLineId: selectedData.selectedProductLine.id,
            bodyAreaId: selectedData.selectedBodyArea.id,
            articleTypeId: selectedData.selectedArticleType?.id,
            footTypeId: selectedData.selectedFootType?.id,
            isPantyTopWithStandardDimensions: selectedData.isPantyTopWithStandardDimensions,
            isEllipticalShapeKneeFunctionZone: selectedData.isEllipticalShapeKneeFunctionZone,
            hideFingersAndToes: selectedData.hideFingersAndToes,
            sideOrder: selectedData.sideOrder,
            selectedBodyInformations: selectedData.selectedArticleType ? selectedData.bodyInformationSelection.map((x, index) => {
                return {
                    id: x.id,
                    index: index
                }
            }) : undefined
        });

        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)