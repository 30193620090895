import React from 'react';
import FormControl from "@mui/material/FormControl";
import CheckIcon from '@mui/icons-material/Check';
import ReportIcon from '@mui/icons-material/Report';
import colors from "shared/style/colors";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import  * as style from './addition_base_translation.style'

interface AdditionBaseTranslationComponentProps {
    hasTranslation: boolean;
  }

export const AdditionBaseTranslationComponent: React.FC<AdditionBaseTranslationComponentProps> = ({ hasTranslation}) => { 
    const name = "Bezeichnungsquelle";
    return (
        <FormControl>
          {hasTranslation ?
            <div>
              <CheckIcon style={{ fill: colors.mediGreen }} />
              <InputLabel htmlFor={name}>{name} </InputLabel>
              <Input
                  id={name}
                  autoFocus={false}
                  disabled={true}
                  inputProps={
                    { 
                      maxLength: 512, 
                      style: 
                      { 
                        padding: 5
                      }
                    }
                  }
                  value="POEditor"
                  disableUnderline
                  error={false}
                />
            </div> :
            <div>
              <ReportIcon style={{ fill: colors.mediRed }} />
              <InputLabel htmlFor={name}>{name} </InputLabel>
              <Input
                id={name}
                autoFocus={false}
                disabled={true}
                inputProps={
                  { 
                    maxLength: 512, 
                    style: 
                    { 
                      color: colors.mediRed,
                      padding: 5
                    }
                  }
                }
                value="EHub"
                error={true}
                disableUnderline
              />
              <style.ErrorWrapper>Es ist kein Übersetzungskey angelegt.</style.ErrorWrapper>
            </div>
          }
      </FormControl>
    );
}